import { Box } from "@mui/material";

import Checked from "../../assets/checkbox-checked.svg";
import UnChecked from "../../assets/checkbox-unchecked.svg";

const CheckBox = ({ checked, onClick }) => {
  return (
    <Box style={{ width: 24, height: 24, marginRight: 12 }}>
      <img
        onClick={onClick}
        src={checked ? Checked : UnChecked}
        style={{ cursor: "pointer" }}
      />
    </Box>
  );
};

export default CheckBox;
