// reducers/company.js

const initialState = {
  comapnies: [],
  compLoading: false,
  selectedCompanyId: "",
  selectedCompany: {},
  filteredCompanies: [],
  editCompLoading: false,
  isCompHide: false,
  accessLoading: false,
  isCollapsed: false,
};

const companyReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_COMP":
      return {
        ...state,
        comapnies: [...state.comapnies, action.payload],
        compLoading: false,
      };

    case "ACCESS_COMP_LOADING_STOP":
      return {
        ...state,
        accessLoading: false,
      };
    case "ACCESS_COMP_LOADING":
      return {
        ...state,
        accessLoading: true,
      };
    case "EDIT_COMP_LOADING_STOP":
      return { ...state, editCompLoading: false };

    case "EDIT_COMP_LOADING":
      return { ...state, editCompLoading: true };
    case "COMP_LOADING":
      return { ...state, compLoading: true };

    case "COMP_LOADING_STOP":
      return { ...state, compLoading: false };

    case "COMPANIES":
      return { ...state, comapnies: action.payload, compLoading: false };

    case "SET_SELECTED_COMP":
      return {
        ...state,
        selectedCompanyId: action.payload._id,
        selectedCompany: action.payload,
      };

    case "DESELECTED_COMP":
      return {
        ...state,
        selectedCompanyId: action.payload.selectedCompanyId,
        selectedCompany: action.payload.selectedCompany,
      };

    case "SET_FILTERED_COMP":
      return { ...state, filteredCompanies: action.payload };

    case "CLEAR_FILTER":
      return { ...state, filteredCompanies: [] };

    case "INIT_STATES":
      return { ...state, ...initialState };

    case "HIDE_COMP":
      return { ...state, isCompHide: true };

    case "SHOW_COMP":
      return { ...state, isCompHide: false };

    case "SET_SIDEBAR_COLLAPSE":
      return { ...state, isCollapsed: !state.isCollapsed };

    default:
      return state;
  }
};

export default companyReducers;
