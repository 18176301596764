import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { GRAPH_TOOLTIP_SETTINGS } from "../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function StackedChart({
  selectedYearData,
  chartData,
  handleClick,
  pdfGraph,
  setPdfGraph,
}) {
  const options = {
    responsive: true,
    animation: {
      duration: 0,
    },
    indexAxis: "y",
    scales: {
      x: {
        ticks: {
          display: false,
        },
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          font: {
            size: 20,
            weight: "bold",
          },
        },
        stacked: true,
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: false,
      title: {
        display: true,
        text: "Financial Gap Calculation (Inventory Optimized)",
        font: {
          size: 20,
        },
      },
      tooltip: {
        enabled: true,
        titleFont: {
          size: GRAPH_TOOLTIP_SETTINGS.titleFont,
        },
        bodyFont: {
          size: GRAPH_TOOLTIP_SETTINGS.bodyFont,
        },
        padding: GRAPH_TOOLTIP_SETTINGS.padding,
      },
    },
  };

  const data = {
    labels: ["Money In", "Money Out"],
    datasets: [
      {
        data: [chartData[0].x, chartData[1].x],
        backgroundColor: ["#2f5597", "#203864"],
        datalabels: {
          font: {
            size: "15px",
            clamp: true,
            weight: "bold",
            color: "red",
          },
          color: "white",
        },
      },
      {
        data: [chartData[2].x, chartData[3].x],
        backgroundColor: ["#203864", "#7a0000"],
        datalabels: {
          font: {
            size: "15px",
            clamp: true,
            weight: "bold",
          },
          color: "white",
        },
      },
    ],
  };
  // const downloadURL = () => {
  //   const imageLink = document.createElement("a");
  //   const canvas = document.getElementsByTagName("canvas");
  //   imageLink.href = canvas[0].toDataURL();
  //   setPdfGraph({ ...pdfGraph, fmfInven: imageLink.href });
  // };
  return (
    <div>
      <Bar
        plugins={[ChartDataLabels]}
        options={options}
        data={data}
        // style={{ minHeight: "100%", minWidth: "100%" }}
        style={{
          height: "500px",
          width: "auto",
        }}
      />
    </div>
  );
}
