export const industries = {
  Manufactoring: "Manufacturing",
  ContructionAndEngineering: "Construction & Engineering",
  ConsumerGoodsAndServices: "Consumer Goods & Services",
  WholesaleAndDistribution: "Wholesale & Distribution",
  BusinessServices: "Business Services",
  BasicMaterialsAndEnergy: "Basic Materials & Energy",
  HealthcareAndBiotech: "Healthcare & Biotech",
  InformationTechnology: "Information Technology",
  FinancialServices: "Financial Services",
  MediaAndEntertainment: "Media & Entertainment",
  AerospaceAndDefense: "Aerospace & Defense",
  Automotive: "Automotive",
  Aviation: "Aviation",
  B2BSaaS: "B2B SaaS",
  ComercialInsurance: "Commercial Insurance",
  Cybersecurity: "Cybersecurity",
  Environmental: "Environmental",
  Fintech: "Fintech",
  HigherEducation: "Higher Education",
  HotelsAndResorts: "Hotels & Resorts",
  HVAC: "HVAC",
  IndustrialIOT: "Industrial IOT",
  LawFirms: "Law Firms & Legal Services",
  OilGas: "Oil & Gas",
  Pharmaceutical: "Pharmaceutical",
  RealEstate: "Real Estate",
  SoftwareDevelopment: "Software Development",
  Staffing: "Staffing & Recruiting",
  Trasportation: "Transportation & Logistics",
};

export const industryVals = {
  Manufactoring: {
    "0k-99k": 4,
    "1M-4.99M": 6,
    "5M-9.99M": 6.3,
    "10M-24.99M": 7,
    "25M-49.99M": 7.3,
    "50M+": 10,
  },
  ContructionAndEngineering: {
    "0k-99k": 3.5,
    "1M-4.99M": 4.5,
    "5M-9.99M": 5.5,
    "10M-24.99M": 6.3,
    "25M-49.99M": 8,
    "50M+": 9,
  },
  ConsumerGoodsAndServices: {
    "0k-99k": 4.3,
    "1M-4.99M": 6,
    "5M-9.99M": 6.5,
    "10M-24.99M": 7,
    "25M-49.99M": 7.5,
    "50M+": 8,
  },
  WholesaleAndDistribution: {
    "0k-99k": 4,
    "1M-4.99M": 5.3,
    "5M-9.99M": 5.5,
    "10M-24.99M": 5.5,
    "25M-49.99M": 7,
    "50M+": 7.3,
  },
  BusinessServices: {
    "0k-99k": 4,
    "1M-4.99M": 6,
    "5M-9.99M": 6.3,
    "10M-24.99M": 7,
    "25M-49.99M": 8,
    "50M+": 8.5,
  },
  BasicMaterialsAndEnergy: {
    "0k-99k": 3,
    "1M-4.99M": 4,
    "5M-9.99M": 6.5,
    "10M-24.99M": 6.5,
    "25M-49.99M": 6.5,
    "50M+": 6.5,
  },
  HealthcareAndBiotech: {
    "0k-99k": 2.5,
    "1M-4.99M": 6.3,
    "5M-9.99M": 4.8,
    "10M-24.99M": 6.5,
    "25M-49.99M": 8.5,
    "50M+": 10,
  },
  InformationTechnology: {
    "0k-99k": 4.5,
    "1M-4.99M": 5.5,
    "5M-9.99M": 6.8,
    "10M-24.99M": 7,
    "25M-49.99M": 9,
    "50M+": 10,
  },
  FinancialServices: {
    "0k-99k": 4.5,
    "1M-4.99M": 5,
    "5M-9.99M": 6.3,
    "10M-24.99M": 8.5,
    "25M-49.99M": 8.5,
    "50M+": 10,
  },
  MediaAndEntertainment: {
    "0k-99k": 5,
    "1M-4.99M": 6,
    "5M-9.99M": 7,
    "10M-24.99M": 8.5,
    "25M-49.99M": 9,
    "50M+": 11,
  },

  AerospaceAndDefense: {
    "0k-99k": 5.6,
    "1M-4.99M": 9.7,
    "5M-9.99M": 11.8,
    "10M-24.99M": 13.1,
    "25M-49.99M": 14.6,
    "50M+": 16.2,
  },

  Automotive: {
    "0k-99k": 3.3,
    "1M-4.99M": 5.7,
    "5M-9.99M": 6.9,
    "10M-24.99M": 7.7,
    "25M-49.99M": 8.6,
    "50M+": 9.6,
  },

  Aviation: {
    "0k-99k": 3.3,
    "1M-4.99M": 5.7,
    "5M-9.99M": 7.6,
    "10M-24.99M": 8.4,
    "25M-49.99M": 9.3,
    "50M+": 10.4,
  },

  B2BSaaS: {
    "0k-99k": 4.3,
    "1M-4.99M": 7.5,
    "5M-9.99M": 9.1,
    "10M-24.99M": 10.2,
    "25M-49.99M": 11.3,
    "50M+": 12.6,
  },

  ComercialInsurance: {
    "0k-99k": 4.0,
    "1M-4.99M": 7.0,
    "5M-9.99M": 8.4,
    "10M-24.99M": 9.4,
    "25M-49.99M": 10.4,
    "50M+": 11.6,
  },
  Cybersecurity: {
    "0k-99k": 4.6,
    "1M-4.99M": 7.9,
    "5M-9.99M": 9.6,
    "10M-24.99M": 10.7,
    "25M-49.99M": 11.9,
    "50M+": 13.2,
  },
  Environmental: {
    "0k-99k": 4.0,
    "1M-4.99M": 6.9,
    "5M-9.99M": 8.3,
    "10M-24.99M": 9.3,
    "25M-49.99M": 10.3,
    "50M+": 11.5,
  },
  Fintech: {
    "0k-99k": 5.6,
    "1M-4.99M": 9.7,
    "5M-9.99M": 11.7,
    "10M-24.99M": 13.0,
    "25M-49.99M": 14.5,
    "50M+": 16.1,
  },
  HigherEducation: {
    "0k-99k": 4.1,
    "1M-4.99M": 7.0,
    "5M-9.99M": 8.5,
    "10M-24.99M": 9.4,
    "25M-49.99M": 10.5,
    "50M+": 11.6,
  },
  HotelsAndResorts: {
    "0k-99k": 5.8,
    "1M-4.99M": 9.9,
    "5M-9.99M": 12.0,
    "10M-24.99M": 13.3,
    "25M-49.99M": 14.8,
    "50M+": 16.5,
  },
  HVAC: {
    "0k-99k": 3.1,
    "1M-4.99M": 4.7,
    "5M-9.99M": 5.7,
    "10M-24.99M": 6.3,
    "25M-49.99M": 7.0,
    "50M+": 7.8,
  },

  IndustrialIOT: {
    "0k-99k": 6.8,
    "1M-4.99M": 11.8,
    "5M-9.99M": 14.1,
    "10M-24.99M": 15.7,
    "25M-49.99M": 17.5,
    "50M+": 19.4,
  },
  LawFirms: {
    "0k-99k": 2.9,
    "1M-4.99M": 4.9,
    "5M-9.99M": 6.0,
    "10M-24.99M": 6.6,
    "25M-49.99M": 7.4,
    "50M+": 8.2,
  },
  OilGas: {
    "0k-99k": 3.8,
    "1M-4.99M": 6.5,
    "5M-9.99M": 7.9,
    "10M-24.99M": 8.7,
    "25M-49.99M": 9.7,
    "50M+": 10.8,
  },
  Pharmaceutical: {
    "0k-99k": 6.3,
    "1M-4.99M": 10.5,
    "5M-9.99M": 12.6,
    "10M-24.99M": 14.0,
    "25M-49.99M": 15.6,
    "50M+": 17.4,
  },
  RealEstate: {
    "0k-99k": 2.9,
    "1M-4.99M": 5.1,
    "5M-9.99M": 6.1,
    "10M-24.99M": 6.8,
    "25M-49.99M": 7.6,
    "50M+": 8.4,
  },
  SoftwareDevelopment: {
    "0k-99k": 3.3,
    "1M-4.99M": 5.4,
    "5M-9.99M": 6.5,
    "10M-24.99M": 7.2,
    "25M-49.99M": 8.0,
    "50M+": 8.9,
  },
  Staffing: {
    "0k-99k": 4.0,
    "1M-4.99M": 6.9,
    "5M-9.99M": 8.4,
    "10M-24.99M": 9.4,
    "25M-49.99M": 10.4,
    "50M+": 11.6,
  },
  Trasportation: {
    "0k-99k": 4.2,
    "1M-4.99M": 7.2,
    "5M-9.99M": 8.7,
    "10M-24.99M": 9.7,
    "25M-49.99M": 10.8,
    "50M+": 12.0,
  },
};
