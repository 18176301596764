import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import store, { Persistor } from "./redux/store";
import { ROUTES } from "./routes/routes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import MobileWarning from "./pages/mobile-message";
import { useMediaQuery } from "@mui/material";

const App = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <React.Fragment>
      {isMobile ? (
        <MobileWarning />
      ) : (
        <Provider store={store}>
          <PersistGate loading={null} persistor={Persistor}>
            <BrowserRouter>
              <Routes>
                {ROUTES?.map((route, index) => (
                  <Route
                    key={index}
                    path={route?.path}
                    element={<route.element />}
                  />
                ))}
              </Routes>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      )}
    </React.Fragment>
  );
};
export default App;
