import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";

const MissingValTooltip = () => {
  return (
    <Tooltip
      title={
        <span style={{ fontSize: "16px" }}>
          Values Missing for this calculation in Financial Data
        </span>
      }
      arrow
      placement="top-start"
    >
      <ErrorIcon style={{ color: "red", marginRight: "4px" }} />
    </Tooltip>
  );
};

export default MissingValTooltip;
