import React, { useEffect, useState } from "react";
import { Header, Sidebar } from "../components";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { HiOutlineHome } from "react-icons/hi2";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import SportsIcon from "@mui/icons-material/Sports";
import { FaRegNoteSticky } from "react-icons/fa6";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { SiMoneygram } from "react-icons/si";
import { TbHome2 } from "react-icons/tb";
import { IoBagOutline } from "react-icons/io5";
import { FaStethoscope } from "react-icons/fa6";
import { TiCloudStorageOutline } from "react-icons/ti";

export const Layout = ({ children, title }) => {
  const dispatch = useDispatch();
  const { isCollapsed } = useSelector((state) => state.companyReducer);

  const { user } = useSelector((state) => state.userReducer);

  const {
    isDashboard,
    isGoalsAndPlanning,
    isGuidedCoaching,
    isFinancialData,
    isInsightAndAnalysis,
    isReports,
  } = useSelector((state) => state.sidenavReducer);

  const { graphLoading } = useSelector((state) => state.pdfGraphReducer);

  const sidebarTabs = [
    {
      section: "Dashboard",
      open: isDashboard,
      content: [
        {
          icon: HiOutlineHome,
          label: "Home",
          route: "/home",
          disabled: false,
        },
      ],
    },
    {
      section: "Goals & Planning",
      open: isGoalsAndPlanning,

      content: [
        {
          icon: PauseCircleOutlineIcon,
          label: "Start With End In Mind",
          route: "/start-with-end-in-mind",
          disabled: false,
        },
      ],
    },
    {
      section: "Guided Coaching",
      open: isGuidedCoaching,

      content: [
        {
          icon: SportsIcon,
          label: "Client Conversation Coach",
          route: "/coach",
          disabled: user?.isClient && !user?.isSubscriber ? true : false,
        },
      ],
    },
    {
      section: "Financial Data",
      open: isFinancialData,

      content: [
        {
          icon: FaRegNoteSticky,
          label: "Financial Data",
          route: "/financial-data",
          disabled: user.isClient,
        },
      ],
    },
    {
      section: "Insight & Analysis",
      open: isInsightAndAnalysis,
      content: [
        {
          icon: HiOutlineCurrencyDollar,
          label: "Hidden Cash",
          route: "/hidden-cash",
          disabled: false,
        },
        {
          icon: SiMoneygram,
          label: "Fast Money Formula",
          route: "/fast-money-formula",
          disabled: false,
        },
        {
          icon: TbHome2,
          label: "Home Run",
          route: "/home-run",
          disabled: false,
        },
        {
          icon: IoBagOutline,
          label: "Simple Valuation",
          route: "/simple-valuation",
          disabled: false,
        },
        {
          icon: FaStethoscope,
          label: "Financial Doctor",
          route: "/financial-doctor",
          disabled: false,
        },
      ],
    },

    {
      section: "Reports",
      open: isReports,
      content: [
        {
          icon: TiCloudStorageOutline,
          label: "Create PDF",
          route: "/pdf",
          disabled: graphLoading || user.isClient,
        },
        {
          icon: FaRegNoteSticky,
          label: "Action Log Report",
          route: "/action-logs",
          disabled: false,
        },
      ],
    },
  ];

  const sidebarMobileView = [
    {
      icon: HiOutlineHome,
      label: "Home",
      route: "/home",
      disabled: false,
    },
    {
      icon: SportsIcon,
      label: "Client Conversation Coach",
      route: "/coach",
      disabled: user?.isClient && !user?.isSubscriber ? true : false,
    },
    {
      icon: PauseCircleOutlineIcon,
      label: "Start With End In Mind",
      route: "/start-with-end-in-mind",
      disabled: false,
    },
    {
      icon: FaRegNoteSticky,
      label: "Financial Data",
      route: "/financial-data",
      disabled: user.isClient,
    },
    {
      icon: HiOutlineCurrencyDollar,
      label: "Hidden Cash",
      route: "/hidden-cash",
      disabled: false,
    },
    {
      icon: SiMoneygram,
      label: "Fast Money Formula",
      route: "/fast-money-formula",
      disabled: false,
    },
    {
      icon: TbHome2,
      label: "Home Run",
      route: "/home-run",
      disabled: false,
    },
    {
      icon: IoBagOutline,
      label: "Simple Valuation",
      route: "/simple-valuation",
      disabled: false,
    },
    {
      icon: FaStethoscope,
      label: "Financial Doctor",
      route: "/financial-doctor",
      disabled: false,
    },
    {
      icon: TiCloudStorageOutline,
      label: "Create PDF",
      route: "/pdf",
      disabled: graphLoading || user.isClient,
    },
  ];

  const [sidebarMenu, setSidebarMenu] = useState(sidebarTabs);

  useEffect(() => {
    sidebarTabs[5].content[0] = {
      icon: TiCloudStorageOutline,
      label: "Create PDF",
      route: "/pdf",
      disabled: graphLoading || user.isClient,
    };

    setSidebarMenu(sidebarTabs);
  }, [graphLoading, user.isClient]);

  const toggleDrawer = () => {
    // setIsCollapsed(!isCollapsed);
    dispatch({
      type: "SET_SIDEBAR_COLLAPSE",
    });
  };

  return (
    <React.Fragment>
      <head>
        <title>{title}</title>
      </head>

      <Grid container xs={12}>
        <Grid item xs={isCollapsed ? 0.5 : 2} style={{ height: "100vh" }}>
          <Sidebar
            isCollapsed={isCollapsed}
            toggleDrawer={toggleDrawer}
            sidebarMenu={sidebarMenu}
            setSidebarMenu={setSidebarMenu}
            sidebarMobileView={sidebarMobileView}
          />
          {!isCollapsed && (
            <KeyboardDoubleArrowLeftIcon
              sx={{
                position: "absolute",
                left: "15.5%",
                top: "6%",
                transform: "translateY(-50%) scaleX(-1)",
                color: "#b4b4b4",
                // border: "none",
                borderRadius: "100%",
                height: "30px",
                width: "30px",
                cursor: "pointer",
                background: "white",
                // fontWeight: "300",
                border: "1px solid #e4e2e2",
                padding: "3px",
                fontSize: "400px",
              }}
              onClick={toggleDrawer}
            />
          )}
        </Grid>
        <Grid
          container
          item
          xs={isCollapsed ? 11.5 : 10}
          style={{ height: "100vh" }}
        >
          <Grid item xs={12} style={{ height: "88px" }}>
            <Header />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              height: "calc(100vh - 88px)",
              padding: 30,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            className="hide_sideBar"
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
