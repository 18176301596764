import React, { useEffect } from "react";
import { Layout } from "../../layout";
import { CardWrapper } from "../../components";
import { Grid, Typography, Box, Tooltip } from "@mui/material";
import CompanyLogo from "../../components/companyLogo";
import { useSelector } from "react-redux";

export default function Coach() {
  const { company } = useSelector((state) => state.companyDetailReducer);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js";
    script.type = "module";
    script.async = true;
    script.onload = () => {
      new window.Landbot.Container({
        container: "#myLandbot",
        configUrl:
          "https://storage.googleapis.com/landbot.site/v3/H-2733729-HB51TQEO72CR9VMC/index.json",
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <React.Fragment>
      <Layout title="Home Run">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CompanyLogo companyName={company.companyName} />
            <Typography className="financial_data_heading">
              {company.companyName}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#F8F8FF",
                      color: "gray",
                      paddingY: "10px",
                      paddingX: "20px",
                    },
                  },
                }}
                title={
                  <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                    {company.NAICStitle}
                  </pre>
                }
                placement="bottom"
                // arrow
              >
                <Typography
                  className="financial_data_right_heading"
                  sx={{ cursor: "pointer" }}
                >
                  NAICS Code: {company.NAICScode}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 10, width: "100%" }} />
        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <div id="myLandbot" style={{ width: "100%", height: "1000px" }} />
        </Grid>
      </Layout>
    </React.Fragment>
  );
}
