import React from "react";
import {
  Grid,
  Button,
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const AddMilestoneModal = ({
  open,
  handleClose,
  years,
  milestoneData,
  setMilestoneData,
  handleSaveMilestone,
  mileStoneSubmitLoading,
  isMSEdit,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h2 style={{ textAlign: "center" }}>
          {isMSEdit ? "Edit" : "Add"} Event
        </h2>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className="main_typo3" sx={{ mt: 1 }}>
              Event Name
            </Typography>
          </div>
          <input
            className="customize-input1"
            placeholder="Enter Event Name"
            value={milestoneData.name}
            color="white"
            name="periodName"
            type="text"
            onChange={(e) => {
              setMilestoneData({
                ...milestoneData,
                name: e.target.value,
              });
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography className="main_typo3" sx={{ mt: 1 }}>
              Date
            </Typography>
            {/* <Typography className="main_typo3" sx={{ mt: 1 }}>
              Period
            </Typography> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
            id="custom-picker"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(milestoneData.date)}
                style={{ borderRadius: "22px" }}
                // disabled={isEdit}
                className="full-width"
                onChange={(newValue) => {
                  setMilestoneData({
                    ...milestoneData,
                    date: newValue.$d,
                  });
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="login-btn"
              //   disabled={loading}
              onClick={handleSaveMilestone}
              id="period-details-btn"
            >
              Submit
              {mileStoneSubmitLoading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )}
            </Button>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default AddMilestoneModal;
