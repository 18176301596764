import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Box,
  Tooltip,
  MenuItem,
  Button,
  Modal,
  Table,
  Divider,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Paper,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import EditIcon from "../../assets/edit-icon.svg";
import TrashIcon from "../../assets/trash-icon.svg";
import CheckBox from "../../components/checkbox/index";
import ActionLogTable from "../../components/action-log-table";

import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchStartWithEndNotes } from "../../redux/actions/notes";
import currency_symbols from "../../utils/currency";
import CurrencyInput from "../../components/CurrencyInput";
import {
  ADD_TRANSFERABLE_VALUE,
  addStartWithEnd,
  fetchLatestSimpleVal,
  fetchStartWithEnd,
  scenDateCheck,
  updateScenario,
  updateStartWithEnd,
} from "../../redux/actions/startWithEnd";
import SnackBar from "../../components/snackBar";
import { Layout } from "../../layout";
import { CardWrapper, Accordian } from "../../components";
import {
  OurPlan,
  OurPerformace,
  CalculateTrans,
  SavingPlan,
  EstimateValue,
} from "../../components/startWithEndComps";
import {
  clientCheck,
  handlInfo,
  removeComma,
  roundUpToNearestThousand,
} from "../../utils/utilFunctions";
import formateDate from "../../utils/formateDate";
import {
  pushTransferableValue,
  deleteScenario,
  updateTransferableValue,
} from "../../redux/actions/startWithEnd";
import { updatePDFData } from "../../redux/actions/pdfGraph";
import { getActionLogByCompany } from "../../redux/actions/actionLog";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const StartWithEndInMind = () => {
  const { startWithEndNotes } = useSelector((state) => state.notesReducer);

  const dispatch = useDispatch();

  const { openSnackbar, snackType, message } = useSelector(
    (state) => state.snackbarReducer
  );

  const { user } = useSelector((state) => state.userReducer);

  const { company, companyDetailsLoading } = useSelector(
    (state) => state.companyDetailReducer
  );

  const { loading } = useSelector((state) => state.startWithEndReducer);

  const startWithEndNotesRef = useRef(null);

  const { startWithEndActionLogs } = useSelector(
    (state) => state.actionLogReducer
  );

  const { selectedCompany } = useSelector((state) => state.companyReducer);

  const [reload, setReload] = useState(false);

  const ourPerPlanRef = useRef(null);
  const calculateTranRef = useRef(null);
  const estimateValSavingPlanRef = useRef(null);
  const tvCapTableRef = useRef(null);

  const initialStartWithEndData = {
    dream: "",
    needInFuture: "",
    needYears: "",
    priorSavings: "",
    yearsUntilExit: "",
    transferableValues: [],
    createdAt: "",
  };

  const [startWithEndData, setStartWithEndData] = useState([
    initialStartWithEndData,
  ]);

  const [selectedScenario, setSelectedScenario] = useState(
    initialStartWithEndData
  );
  const [scenarioName, setScenarioName] = useState("");
  const [scenarioDate, setScenarioDate] = useState("");
  const [scenarioIsIncludeSales, setScenarioIsIncludeSales] = useState(false);
  const [isScenDateEdit, setIsScenDateEdit] = useState(false);
  const [deleteScen, setDeleteScen] = useState();
  const [scenDeleteModalOpen, setScenDeleteModalOpen] = useState(false);
  const [isScenEdit, setIsScenEdit] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [openScenarioModal, setOpenScenarioModal] = useState(false);
  const [dream, setDream] = useState("");
  const [needInFuture, setNeedInFuture] = useState("");
  const [needYears, setNeedYears] = useState("");
  const [yearsUntilExit, setYearsUntilExit] = useState("");
  const [priorSaving, setPriorSaving] = useState("");
  const [transferableValues, setTransferableValues] = useState([]);
  const [tvGoal, setTvGoal] = useState();
  const [currSavingGoal, setCurrSavingGoal] = useState();
  const [latestEquity, setLatestEquity] = useState();
  const [firstEquity, setFirstEquity] = useState();
  const [monthlyGoal, setMonthlyGoal] = useState();
  const [tvCap, setTvCap] = useState();
  const [tvCapDate, setTvCapDate] = useState();
  const [isAddTv, setIsAddTv] = useState(false);
  const [isTvEdit, setIsTvEdit] = useState(false);
  const [tvEditIndex, setTvEditIndex] = useState();
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [tvDeleteModalOpen, setTvDeleteModalOpen] = useState(false);
  const [tvDeleteIndex, setTvDeleteIndex] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [isGraphUpdate, setIsGraphUpdate] = useState("");

  useEffect(() => {
    fetchStartWithEndData();
  }, []);

  const handleSetStartWithEndValue = (swe) => {
    if (!swe) return;
    setSelectedScenario(swe);
    setDream(swe?.dream || "");
    setNeedInFuture(swe?.needInFuture || "");
    setNeedYears(swe?.needYears || 20);
    setPriorSaving(swe?.priorSaving || "");
    setYearsUntilExit(swe?.yearsUntilExit || "");
    setTransferableValues(swe?.transferableValues || []);
    setCreatedAt(formateDate(swe?.created_at || ""));

    const dumTv =
      parseInt(removeComma(swe.needInFuture)) *
      (swe.needYears ? parseInt(swe.needYears) : 20);
    const dumCurrSavingGoal = dumTv - parseInt(removeComma(swe.priorSaving));

    handleTransferableValueGoal(
      parseInt(removeComma(swe.needInFuture)),
      parseInt(swe.needYears)
    );
    handleCurrSavingGoal(dumTv, parseInt(removeComma(swe.priorSaving)));
    handleTVYearGoal(
      parseInt(removeComma(swe.needInFuture)),
      swe.needYears ? parseInt(swe.needYears) : 20
    );
    handleMonthlyGoal(dumCurrSavingGoal, parseInt(swe.yearsUntilExit));
  };

  const fetchStartWithEndData = async (selecedt) => {
    const res = await dispatch(fetchStartWithEnd(company._id));

    const { data } = res.data;

    setStartWithEndData(data);

    let slcted = data[0];

    if (selecedt) {
      slcted = selecedt;
    }

    handleSetStartWithEndValue(slcted);
  };

  useEffect(() => {
    handleLatestSimpleVal();

    if (Object.keys(company).length) {
      const payload = {
        companyId: company._id,
        page: "StartWithEnd",
      };
      dispatch(fetchStartWithEndNotes(payload));
    }
  }, [company]);

  useEffect(() => {
    const payload = {
      companyId: selectedCompany._id,
      type: "StartWithEnd",
    };
    dispatch(getActionLogByCompany(payload));
  }, [dispatch, selectedCompany._id, reload]);

  const handleLatestSimpleVal = async () => {
    const result = await dispatch(fetchLatestSimpleVal(company._id));

    setLatestEquity(result.data.data.equityVal);

    const val = result.data.data.firstEquityVal;

    setFirstEquity(val);
  };

  const conversionThousandToK = (amount) => {
    if (amount >= 1000) {
      return (
        currency_symbols[company.currency] + (amount / 1000).toFixed(0) + "K"
      ); // Round to 1 decimal place
    }
    return amount; // Return the original value if less than 1000
  };

  const formateWithCurrency = (amount) => {
    if (!amount) return `${currency_symbols[company.currency]}0`;
    const dumAmount = parseInt(amount.toFixed(0));
    return `${currency_symbols[company.currency]}${dumAmount.toLocaleString()}`;
  };

  const handleAddTv = () => {
    // if (!clientCheck(user, dispatch)) {
    //   return;
    // }
    setIsAddTv(!isAddTv);
  };

  const handleSave = async () => {
    if (
      !dream ||
      !needInFuture ||
      !needYears ||
      !yearsUntilExit ||
      !priorSaving
    ) {
      return dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Required all values",
        },
      });
    }

    setIsGraphUpdate((prev) => !prev);

    const payload = {
      scenarioName,
      scenarioDate,
      companyId: company._id,
      userId: user._id,
      dream,
      needInFuture,
      needYears,
      yearsUntilExit,
      priorSaving,
      isIncludeSales: scenarioIsIncludeSales,
    };

    if (isScenEdit) {
      const response = await dispatch(
        updateStartWithEnd(selectedScenario._id, payload)
      );
      fetchStartWithEndData(response.data.data);
      return;
    }

    await dispatch(addStartWithEnd(payload));
    fetchStartWithEndData();
  };

  const handleTransferableValueGoal = (needInFuture, needYars) => {
    const newTvGoal =
      parseInt(removeComma(needInFuture)) *
      parseInt(needYars ? needYars : needYears);

    setTvGoal(newTvGoal);
    return newTvGoal;
  };

  const handleTVYearGoal = (needInFutre, needYars) => {
    const dumYear = needYars ? needYars : 1;

    setTvGoal(parseInt(removeComma(needInFutre)) * parseInt(dumYear));
  };

  const handleCurrSavingGoal = (tvGoal_, priorSaving) => {
    setCurrSavingGoal(parseInt(tvGoal_) - parseInt(removeComma(priorSaving)));
  };

  const handleMonthlyGoal = (currSavingGoal, yearsUntilExit) => {
    const dumCurrSavingGoal = removeComma(currSavingGoal);
    if (currSavingGoal && yearsUntilExit) {
      setMonthlyGoal(
        roundUpToNearestThousand(
          parseInt(dumCurrSavingGoal) / (yearsUntilExit * 12)
        ).toFixed(0)
      );
    }
  };

  const handleTvCapInput = () => {
    if (!tvCap || !tvCapDate) {
      return dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "TV and Date required!",
        },
      });
    }

    setTransferableValues([
      ...transferableValues,
      { date: formateDate(tvCapDate), amount: tvCap },
    ]);

    const payload = {
      date: tvCapDate,
      amount: tvCap,
      scenarioDate: selectedScenario.scenarioDate,
    };

    dispatch(pushTransferableValue(company._id, payload));
    setTvCap();
    setTvCapDate();
    setIsAddTv(false);
  };

  const handleTvCapInputUpdate = async () => {
    if (!tvCap || !tvCapDate) {
      return dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "TV and Date required!",
        },
      });
    }

    const dummyArr = [...transferableValues];

    dummyArr[tvEditIndex].date = formateDate(tvCapDate);
    dummyArr[tvEditIndex].amount = tvCap;

    const payload = {
      transferableValues: dummyArr,
      scenarioDate: selectedScenario.scenarioDate,
    };

    await dispatch(updateTransferableValue(company._id, payload));

    setIsTvEdit(false);
    setTvEditIndex();
    setIsAddTv(false);
  };

  const handleDeleteTv = async (tv, indexToRemove) => {
    // if (!clientCheck(user, dispatch)) {
    //   return;
    // }
    setTvDeleteModalOpen(true);
    setTvDeleteIndex(indexToRemove);
  };

  const handleConfirmTvDelet = async () => {
    let dumTvArr = [...transferableValues];

    dumTvArr = dumTvArr.filter((_, index) => index !== tvDeleteIndex);

    const payload = {
      transferableValues: dumTvArr,
    };
    await dispatch(updateTransferableValue(company._id, payload));

    setTransferableValues(dumTvArr);

    dispatch({
      type: ADD_TRANSFERABLE_VALUE,
      payload: {
        transferableValues: dumTvArr,
      },
    });

    setTvDeleteModalOpen(false);
    setTvDeleteIndex();
  };

  const handleShowDetails = () => {
    setIsShowDetail(!isShowDetail);
    setIsGraphUpdate((prev) => !prev);
  };

  useEffect(() => {
    savePDF();
  }, [isGraphUpdate]);

  const savePDF = () => {
    setTimeout(() => {
      saveSWEMPDF();
    }, 1500);
  };

  const saveSWEMPDF = async () => {
    try {
      dispatch({
        type: "GRAPH_LOADING",
      });
      if (!ourPerPlanRef.current) {
        dispatch({
          type: "STOP_GRAPH_LOADING",
        });
        return;
      }

      const ourPlanPerCanvas = await html2canvas(ourPerPlanRef.current);
      const ourPlanPer64Image = await ourPlanPerCanvas.toDataURL("image/png");

      const calculateTranCanvas = await html2canvas(calculateTranRef.current);
      const calculateTran64Image = await calculateTranCanvas.toDataURL(
        "image/png"
      );

      const estimateValSavingPlanCanvas = await html2canvas(
        estimateValSavingPlanRef.current
      );
      const estimateValSavingPlan64Image =
        await estimateValSavingPlanCanvas.toDataURL("image/png");

      const startWithEndNotesCanvas = await html2canvas(
        startWithEndNotesRef.current
      );
      const startWithEndNotes64Image = await startWithEndNotesCanvas.toDataURL(
        "image/png"
      );

      const payload = {
        notes: {
          estimateValSavingPlan: startWithEndNotes64Image,
        },
        images: {
          ourPerPlan: ourPlanPer64Image,
          calculateTran: calculateTran64Image,
          estimateValSavingPlan: estimateValSavingPlan64Image,
        },
      };

      dispatch(updatePDFData(payload));
      dispatch({
        type: "STOP_GRAPH_LOADING",
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSaleOfBusiness = (
    shouldIncludeSales,
    latestEquity,
    tvCap,
    priorSaving
  ) => {
    const dumLatestEquity = shouldIncludeSales
      ? latestEquity
        ? parseInt(latestEquity)
        : 0
      : 0;
    const dumTvCap = tvCap ? parseInt(tvCap) : 0;
    const dumPriorSaving = priorSaving ? parseInt(priorSaving) : 0;

    return formateWithCurrency(dumLatestEquity + dumTvCap + dumPriorSaving);
  };

  const handleSWEEdit = (tv, index) => {
    // if (!clientCheck(user, dispatch)) {
    //   return;
    // }

    setIsTvEdit(true);
    setIsAddTv(!isAddTv);
    setTvCap(tv.amount);
    setTvCapDate(tv.date);
    setTvEditIndex(index);
  };

  const handleScenarioModalClose = () => {
    setOpenScenarioModal(false);
  };

  const handleScenarioModalOpen = () => {
    setOpenScenarioModal(true);
  };

  const handleAddNewScen = () => {
    setDream("");
    setNeedInFuture("");
    setNeedYears("");
    setPriorSaving("");
    setYearsUntilExit("");
    setTransferableValues([]);
    setCreatedAt("");
  };

  const handleSelectScenario = (e) => {
    const dumScen = startWithEndData.find((x) => x._id === e.target.value);
    handleSetStartWithEndValue(dumScen);
    setSelectedScenario(dumScen);
    setScenarioDate(dumScen.scenarioDate);
    setScenarioName(dumScen.scenarioName);
    setScenarioIsIncludeSales(dumScen.isIncludeSales);
    setIsScenEdit(true);
  };

  const handleClickEditScenario = (scen) => {
    setIsScenDateEdit(true);
    handleScenarioModalOpen();
    setIsScenEdit(true);
    setScenarioName(scen.scenarioName);
    setScenarioDate(scen.scenarioDate);
    setScenarioIsIncludeSales(scen.isIncludeSales);
  };

  const handleDeleteScenario = (scen) => {
    setScenDeleteModalOpen(true);
    setDeleteScen(scen);
  };

  const handleConfirmDeleteScenario = async () => {
    await dispatch(deleteScenario(deleteScen._id));
    fetchStartWithEndData();
    setScenDeleteModalOpen(false);
  };

  const handleSubmitScenDate = async () => {
    if (!scenarioName || !scenarioDate) {
      return dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Required all values",
        },
      });
    }

    try {
      if (isScenDateEdit) {
        const response = await dispatch(
          updateScenario(company._id, {
            scenId: selectedScenario._id,
            scenarioName,
            scenarioDate,
            isIncludeSales: scenarioIsIncludeSales,
          })
        );

        if (response.status === 200) {
          fetchStartWithEndData(response.data.data);
          handleScenarioModalClose();
        }

        setIsScenDateEdit(false);
      } else {
        const resp = await dispatch(
          scenDateCheck(company._id, { scenarioName, scenarioDate })
        );

        if (resp) {
          setSelectedScenario(resp.data.data);
          setStartWithEndData([...startWithEndData, { ...resp.data.data }]);
        }

        handleScenarioModalClose();
        if (!isScenEdit) handleAddNewScen();
        setIsScenEdit(true);
      }
    } catch (error) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: error?.response?.data?.message || "Something went wrong",
        },
      });
      dispatch({ type: "STOP_LOADING" });
      return;
    }
  };

  const handleIncludeSaleOfBsnsCheck = () => {
    setScenarioIsIncludeSales(!scenarioIsIncludeSales);
  };

  const generateRandomLightColor = () => {
    return `#b7959b`;
  };

  const CompanyLogo = ({ companyName }) => {
    // Get the first letter of the company name
    const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

    // Style for the red circle
    const circleStyle = {
      width: "50px", // Set your desired logo size
      height: "50px", // Set your desired logo size
      borderRadius: "50%",
      backgroundColor: generateRandomLightColor(),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "1.5em",
      marginRight: "10px",
    };

    return <div style={circleStyle}>{firstLetter}</div>;
  };

  return (
    <React.Fragment>
      <Layout title="Start With End In Mind">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CompanyLogo companyName={company.companyName} />
            <Typography className="financial_data_heading">
              {company.companyName}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#F8F8FF",
                      color: "gray",
                      paddingY: "10px",
                      paddingX: "20px",
                    },
                  },
                }}
                title={
                  <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                    {company.NAICStitle}
                  </pre>
                }
                placement="bottom"
                // arrow
              >
                <Typography
                  className="financial_data_right_heading"
                  sx={{ cursor: "pointer" }}
                >
                  NAICS Code: {company.NAICScode}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 10, width: "100%" }} />

        <div style={{ padding: "20px" }}>
          <Typography className="hidden_cash_subTitle">
            Start With End In Mind{" "}
            <InfoOutlinedIcon
              onClick={() => handlInfo("sweim")}
              className="infor_icon"
            />
          </Typography>
        </div>

        <Box style={{ height: 10, width: "100%" }} />
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid width={"100%"} ref={ourPerPlanRef}>
              <CardWrapper border="1px solid #E7E7E7">
                <Typography variant="h6" fontWeight="bold" mb={4}>
                  {" "}
                  Our Plan -{" "}
                  <span style={{ color: "#00bdff" }}>
                    Build Transferable Value:{" "}
                    {currSavingGoal ? formateWithCurrency(currSavingGoal) : 0}
                  </span>
                </Typography>
                <OurPlan
                  yearsUntilExit={yearsUntilExit}
                  monthlyGoal={monthlyGoal}
                  createdAt={createdAt}
                  firstEquity={firstEquity}
                  formateWithCurrency={formateWithCurrency}
                  conversionThousandToK={conversionThousandToK}
                  selectedScenario={selectedScenario}
                  latestEquity={latestEquity}
                  tvGoal={tvGoal}
                  scenarioIsIncludeSales={scenarioIsIncludeSales}
                />
              </CardWrapper>
              <Box style={{ height: 30, width: "100%" }} />
              <CardWrapper border="1px solid #E7E7E7">
                <Grid
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" mb={4}>
                    {" "}
                    Our Performance -{" "}
                    <span style={{ color: "#00bdff" }}>
                      Transferable Value Goal:{" "}
                      {handleSaleOfBusiness(
                        scenarioIsIncludeSales,
                        latestEquity,
                        transferableValues.length
                          ? transferableValues?.reduce(
                              (sum, item) =>
                                sum + parseInt(removeComma(item.amount)),
                              0
                            )
                          : [],
                        parseInt(priorSaving?.replace(/,/g, ""), 10)
                      )}{" "}
                      / {tvGoal ? formateWithCurrency(parseInt(tvGoal)) : "0"}
                    </span>
                  </Typography>
                  <Grid display="flex" alignItems={"center"}>
                    <Typography
                      mr={2}
                      sx={{
                        textDecoration: "underline",
                        color: "#00bdff",
                        cursor: "pointer",
                      }}
                      onClick={handleShowDetails}
                    >
                      {isShowDetail ? "Hide Details" : "Show Details"}
                    </Typography>
                    <Button onClick={handleAddTv} className={"add_tv"}>
                      Add TV
                    </Button>
                  </Grid>
                </Grid>
                {isShowDetail ? (
                  <TableContainer
                    component={Paper}
                    sx={{
                      backgroundColor: "transparent",
                      height: 300,
                      width: 400,
                      marginBottom: "30px",
                    }}
                    ref={tvCapTableRef}
                  >
                    <Table sx={{ minWidth: 200 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "700" }}>Date</TableCell>
                          <TableCell sx={{ fontWeight: "700" }} align="right">
                            Amount
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "700",
                              float: "left",
                            }}
                            align="right"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transferableValues.length ? (
                          transferableValues.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{formateDate(row.date)}</TableCell>
                              <TableCell align="right">
                                {formateWithCurrency(
                                  parseInt(row?.amount?.replace(/,/g, ""), 10)
                                )}
                              </TableCell>
                              <TableCell>
                                <Grid display={"flex"}>
                                  <img
                                    onClick={() => handleSWEEdit(row, index)}
                                    src={EditIcon}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    alt="edit icon"
                                  />
                                  <img
                                    onClick={() => handleDeleteTv(row, index)}
                                    src={TrashIcon}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "15px",
                                    }}
                                    alt="delete icon"
                                  />
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <span style={{ color: "#b0b0b0" }}>
                            No Data Added
                          </span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  ""
                )}
                <OurPerformace
                  priorSaving={priorSaving}
                  tvGoal={tvGoal}
                  latestEquity={latestEquity}
                  selectedScenario={selectedScenario}
                  transferableValues={transferableValues}
                  createdAt={createdAt}
                  yearsUntilExit={yearsUntilExit}
                  formateWithCurrency={formateWithCurrency}
                />
              </CardWrapper>
            </Grid>
            <Box style={{ height: 30, width: "100%" }} />
            <Grid width={"100%"} ref={calculateTranRef}>
              <CardWrapper border="1px solid #E7E7E7">
                <CalculateTrans
                  dream={dream}
                  setDream={setDream}
                  needInFuture={needInFuture}
                  setNeedInFuture={setNeedInFuture}
                  needYears={needYears}
                  setNeedYears={setNeedYears}
                  yearsUntilExit={yearsUntilExit}
                  setYearsUntilExit={setYearsUntilExit}
                  priorSaving={priorSaving}
                  setPriorSaving={setPriorSaving}
                  handleSave={handleSave}
                  tvGoal={tvGoal}
                  company={company}
                  currSavingGoal={currSavingGoal}
                  setCurrSavingGoal={setCurrSavingGoal}
                  handleTransferableValueGoal={handleTransferableValueGoal}
                  handleTVYearGoal={handleTVYearGoal}
                  handleCurrSavingGoal={handleCurrSavingGoal}
                  handleMonthlyGoal={handleMonthlyGoal}
                  formateWithCurrency={formateWithCurrency}
                  currency={currency_symbols[company.currency]}
                  handleScenarioModalOpen={handleScenarioModalOpen}
                  user={user}
                  dispatch={dispatch}
                  startWithEndData={startWithEndData}
                  selectedScenario={selectedScenario}
                  setSelectedScenario={setSelectedScenario}
                  handleSelectScenario={handleSelectScenario}
                  handleClickEditScenario={handleClickEditScenario}
                  handleDeleteScenario={handleDeleteScenario}
                  setScenarioName={setScenarioName}
                  setScenarioDate={setScenarioDate}
                  setScenarioIsIncludeSales={setScenarioIsIncludeSales}
                  setIsScenEdit={setIsScenEdit}
                />
              </CardWrapper>
            </Grid>

            <Box style={{ height: 30, width: "100%" }} />
            <Grid width={"100%"} ref={estimateValSavingPlanRef}>
              <CardWrapper border="1px solid #E7E7E7">
                <SavingPlan
                  currSavingGoal={currSavingGoal}
                  monthlyGoal={monthlyGoal}
                  yearsUntilExit={yearsUntilExit}
                  formateWithCurrency={formateWithCurrency}
                  conversionThousandToK={conversionThousandToK}
                />
              </CardWrapper>
              <Box style={{ height: 30, width: "100%" }} />
              <CardWrapper border="1px solid #E7E7E7">
                <EstimateValue
                  latestEquity={latestEquity}
                  formateWithCurrency={formateWithCurrency}
                  user={user}
                  selectedScenario={selectedScenario}
                />
              </CardWrapper>
            </Grid>
            <Box style={{ height: 30, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <Accordian
                noteData={startWithEndNotes}
                key1="startWithEndNotes"
                notesRef={startWithEndNotesRef}
                setIsGraphUpdate={setIsGraphUpdate}
              />
              <Box style={{ height: 20, width: "100%" }} />
              <Divider />
              <Box style={{ height: 20, width: "100%" }} />
              <ActionLogTable
                setReload={setReload}
                actionLogs={startWithEndActionLogs}
                // periodData={periodData}
                selectedCompany={selectedCompany}
                user={user}
                type="StartWithEnd"
              />
            </CardWrapper>
          </Grid>
        </CardWrapper>
      </Layout>

      <Modal
        open={isAddTv}
        onClose={() => setIsAddTv(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ textAlign: "center" }}>Enter TV Details</h2>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Transferable Value
              </Typography>
            </div>
            <CurrencyInput
              className="customize-input1"
              placeholder="Enter Transferable Value"
              value={tvCap}
              color="white"
              type="text"
              onChange={(e) => setTvCap(e.target.value)}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Date
              </Typography>
            </Box>
            <Box
              // sx={{ display: "flex", gap: "10px", alignItems: "center" }}
              id="custom-picker"
              style={{ width: "100%" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={tvCapDate ? dayjs(tvCapDate) : null}
                  style={{
                    borderRadius: "22px",
                  }}
                  className="full-width"
                  // disabled={isEdit}
                  onChange={(newValue) => {
                    setTvCapDate(newValue.$d);
                  }}
                />
              </LocalizationProvider>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="login-btn"
                // disabled={companyDetailsLoading}
                onClick={isTvEdit ? handleTvCapInputUpdate : handleTvCapInput}
                id="period-details-btn"
              >
                {isTvEdit ? "Update" : "Submit"}
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5%",
                    }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>

      <Modal
        open={tvDeleteModalOpen}
        onClose={() => setTvDeleteModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Typography variant="h6" align="center">
              Are you sure you want to delete this TV?
            </Typography>

            <Button
              className="login-btn"
              sx={{ float: "left" }}
              onClick={handleConfirmTvDelet}
            >
              Yes
              {loading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )}
            </Button>
            <Button
              className="login-btn"
              sx={{ float: "right" }}
              onClick={() => setTvDeleteModalOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </>
      </Modal>

      <Modal
        open={scenDeleteModalOpen}
        onClose={() => setScenDeleteModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Typography variant="h6" align="center">
              Are you sure you want to delete this Scenario?
            </Typography>

            <Button
              className="login-btn"
              sx={{ float: "left" }}
              onClick={handleConfirmDeleteScenario}
            >
              Yes
              {loading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )}
            </Button>
            <Button
              className="login-btn"
              sx={{ float: "right" }}
              onClick={() => setScenDeleteModalOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </>
      </Modal>

      <Modal
        open={openScenarioModal}
        onClose={handleScenarioModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ textAlign: "center" }}>Enter Details</h2>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Scenario Name
              </Typography>
            </div>
            <input
              className="customize-input1"
              placeholder="Enter Scenario Name"
              value={scenarioName ? scenarioName : ""}
              color="white"
              name="scenarioName"
              type="text"
              onChange={(e) => {
                setScenarioName(e.target.value);
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Scenario Date
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "200%",
              }}
              id="custom-picker"
              className="width-full"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(scenarioDate)}
                  style={{ borderRadius: "22px", width: "200%" }}
                  // disabled={isEdit}
                  onChange={(newValue) => {
                    setScenarioDate(formateDate(newValue.$d));
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <CheckBox
                checked={scenarioIsIncludeSales}
                onClick={handleIncludeSaleOfBsnsCheck}
              />
              <Typography className={"comp_name"}>
                Include Sale of Business
              </Typography>{" "}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="login-btn"
                disabled={companyDetailsLoading}
                onClick={handleSubmitScenDate}
                id="period-details-btn"
              >
                Submit
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5%",
                    }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>

      <SnackBar open={openSnackbar} snackType={snackType} msg={message} />
    </React.Fragment>
  );
};
export default StartWithEndInMind;
