import {
  Grid,
  Typography,
  Box,
  Button,
  Modal,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import EditIcon2 from "@mui/icons-material/ModeEdit";
import DeleteIcon1 from "@mui/icons-material/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddActionModal from "../add-action-modal";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteActionLog,
  getActionLogByCompany,
  editActionLog,
} from "../../redux/actions/actionLog";
import { ACTION_LOG_CATEGORIES } from "../../utils/constants";
import { handleShowMetric } from "../../utils/utilFunctions";

const ActionLogTable = ({
  periodData,
  selectedCompany,
  user,
  type,
  setReload,
  actionLogs,
}) => {
  const [open, setOpen] = useState(false);
  const [logData, setLogData] = useState({
    actionDescription: "",
    expectedResult: "",
    dateOfAction: "",
    category: "",
    status: "",
  });
  const [selectedActionLogId, setSelectedActionLogId] = useState();
  const [deletLogModelOpen, setDeletLogModelOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [loadingData, setLoadingData] = useState({
    index: 0,
    type: "",
    isLoading: false,
  });

  const [editingField, setEditingField] = useState({
    index: null,
    field: null,
    value: "",
    logId: null,
  });

  const dispatch = useDispatch();
  const handleOpenDeletLogModel = (item) => {
    setDeletLogModelOpen(true);
    setSelectedActionLogId(item._id);
  };

  const handleDeleteLog = async () => {
    setDeleteLoading(true);
    await dispatch(deleteActionLog(selectedActionLogId));
    setDeletLogModelOpen(false);
    setReload((prev) => !prev);
    setDeleteLoading(false);
  };

  const handleDeletLogModelClose = () => {
    setDeletLogModelOpen(false);
  };

  const handleActionEdit = (item) => {
    setSelectedActionLogId(item._id);

    setLogData({
      actionDescription: item.description,
      expectedResult: item.expectedResult,
      dateOfAction: item.date,
      category: item.category,
      status: item.status,
    });
    setOpen(true);
    setReload((prev) => !prev);
  };

  const handleUpdateLog = async (logId, value, index) => {
    console.log("value", value);
    setLoadingData({
      ...loadingData,
      index,
      type,
      isLoading: true,
    });
    const payload = {
      status: value,
    };
    await dispatch(editActionLog(logId, payload));
    dispatch(getActionLogByCompany({ companyId: selectedCompany._id }));
    setEditingField({ index: null, field: null, value: "", logId: null });
    setReload((prev) => !prev);
    setLoadingData({ ...loadingData, isLoading: false, index: null, type: "" });
  };

  console.log("loadingDAta", loadingData);

  return (
    <Grid container className="action-log-table-container">
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          className="action-log-typo"
          style={{
            fontWeight: "bolder",
            fontSize: 18,
          }}
        >
          Action Log
        </Typography>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#201b5b",
            textTransform: "capitalize",
          }}
          onClick={() => setOpen(true)}
        >
          + Add Action
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        className="hide_scrollBar"
        style={{ maxHeight: "300px", overflow: "auto" }}
      >
        <table className="action-log-table" style={{ marginTop: 20 }}>
          <tr
            className="action-log-row"
            style={{ position: "sticky", top: 0, backgroundColor: "white" }}
          >
            <th className="action-log-header">
              <b>Date</b>
            </th>
            <th className="action-log-header">
              <b>Status</b>
            </th>
            <th className="action-log-header">
              <b>Category</b>
            </th>
            <th className="action-log-header">
              <b>Description</b>
            </th>
            <th className="action-log-header">
              <b>Expected Result</b>
            </th>
            <th className="action-log-header">
              <b>Manage</b>
            </th>
          </tr>
          {actionLogs && actionLogs.length > 0 ? (
            actionLogs.map((item, index) =>
              item.type === type ? (
                <tr className="action-log-row" key={item._id}>
                  <td className="action-log-data">
                    {new Date(item?.date).toLocaleDateString("en-US", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </td>
                  <td className="action-log-data">
                    {/* <Chip
                      label={item?.status}
                      color={
                        item?.status === "Successful"
                          ? "success"
                          : item?.status === "In Progress"
                          ? "warning"
                          : item?.status === "Pending"
                          ? "info"
                          : item?.status === "Abandoned"
                          ? "error"
                          : item?.status === "Failed"
                          ? "error"
                          : "default"
                      }
                    /> */}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {loadingData.index === index && loadingData.isLoading && (
                        <CircularProgress
                          color="inherit"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "5%",
                          }}
                        />
                      )}

                      <Select
                        value={item?.status || ""}
                        onChange={(e) =>
                          handleUpdateLog(item?._id, e.target.value, index)
                        }
                        size="small"
                        sx={{
                          minWidth: 130,
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Remove the border
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Remove the border on focus as well
                          },
                          "& .MuiSelect-select": {
                            padding: "4px 8px",
                          },
                        }}
                        name="status"
                      >
                        <MenuItem value="Successful">
                          <Chip
                            label="Successful"
                            color="success"
                            size="small"
                          />
                        </MenuItem>
                        <MenuItem value="In Progress">
                          <Chip
                            label="In Progress"
                            color="warning"
                            size="small"
                          />
                        </MenuItem>
                        <MenuItem value="Pending">
                          <Chip label="Pending" color="info" size="small" />
                        </MenuItem>
                        <MenuItem value="Abandoned">
                          <Chip label="Abandoned" color="error" size="small" />
                        </MenuItem>
                        <MenuItem value="Failed">
                          <Chip label="Failed" color="error" size="small" />
                        </MenuItem>
                      </Select>
                    </div>
                  </td>
                  <td className="action-log-data">
                    {ACTION_LOG_CATEGORIES[item?.category]?.name}
                  </td>
                  <td className="action-log-data">{item?.description}</td>
                  <td className="action-log-data">{item?.expectedResult}</td>
                  <td className="action-log-data">
                    <Box style={{ display: "flex", gap: "10px" }}>
                      <EditIcon2
                        onClick={() => handleActionEdit(item)}
                        className="Home_Action_button"
                        style={{ cursor: "pointer" }}
                        alt="edit icon"
                      />
                      <DeleteIcon1
                        onClick={() => handleOpenDeletLogModel(item)}
                        className="Home_Action_button"
                        style={{ cursor: "pointer" }}
                        alt="delete icon"
                      />
                    </Box>
                  </td>
                </tr>
              ) : null
            )
          ) : (
            <tr>
              <td colSpan={5} align="center">
                <Typography variant="body1" sx={{ py: 2 }}>
                  No action logs found
                </Typography>
              </td>
            </tr>
          )}
        </table>
      </Grid>
      <AddActionModal
        open={open}
        setOpen={setOpen}
        periodData={periodData}
        selectedCompany={selectedCompany}
        user={user}
        type={type}
        logData={logData}
        setLogData={setLogData}
        selectedActionLogId={selectedActionLogId}
        setSelectedActionLogId={setSelectedActionLogId}
        setReload={setReload}
      />

      <Modal
        open={deletLogModelOpen}
        onClose={handleDeletLogModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "#efefef",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <Typography variant="h6" align="center">
              Are you sure you want to delete this log?
            </Typography>

            <Button
              className="login-btn"
              sx={{ float: "left" }}
              onClick={handleDeleteLog}
              disabled={deleteLoading}
            >
              Yes
              {deleteLoading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )}{" "}
            </Button>
            <Button
              className="login-btn"
              sx={{ float: "right" }}
              onClick={handleDeletLogModelClose}
            >
              Cancel
            </Button>
          </Box>
        </>
      </Modal>
    </Grid>
  );
};

export default ActionLogTable;
