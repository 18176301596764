import * as React from "react";
// import Stack from "@mui/material/Stack";
import Snackbar from "@material-ui/core/Snackbar";

import Box from "@material-ui/core/Box";

// import MuiAlert from "@mui/material/Alert";
import MuiAlert from "@material-ui/lab/Alert";

import { imediateClear } from "../../utils/utilFunctions";
import { useDispatch } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({ open, setOpen, snackType, msg }) {
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    imediateClear(dispatch);
    // setOpen(false);
  };
  // const state = {
  //   vertical: "top",
  //   horizontal: "center",
  // };

  // const { vertical, horizontal } = state;

  return (
    <Box spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackType}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
