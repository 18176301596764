import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { roundUpToNearestThousand } from "../../utils/utilFunctions";

const StyledTableHeader = styled(TableCell)({
  backgroundColor: "deepskyblue",
  color: "white",
  fontWeight: "bold",
});

const StyledTableHeader2 = styled(TableCell)({
  backgroundColor: "#201b5b",
  color: "white",
  fontWeight: "bold",
});

const SavingPlan = ({
  yearsUntilExit,
  currSavingGoal,
  monthlyGoal,
  formateWithCurrency,
  conversionThousandToK,
}) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography variant="h6" fontWeight="bold" mb={4}>
          Savings Plan Details
        </Typography>

        <Grid display={"flex"} alignContent={"center"}>
          <Grid sm={8} lg={8} md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeader sx={{ width: "300px" }}>
                      Saving Interval
                    </StyledTableHeader>
                    <StyledTableHeader sx={{ width: "300px" }}>
                      Monthly
                    </StyledTableHeader>
                    <StyledTableHeader sx={{ width: "300px" }}>
                      Yearly
                    </StyledTableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: "300px" }}>
                      Amount to save
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      {currSavingGoal && yearsUntilExit
                        ? formateWithCurrency(
                            currSavingGoal / (yearsUntilExit * 12)
                          )
                        : 0}
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      {currSavingGoal && yearsUntilExit
                        ? formateWithCurrency(currSavingGoal / yearsUntilExit)
                        : 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box style={{ height: 30, width: "100%" }} />

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeader2 sx={{ width: "300px" }}>
                      Time Interval
                    </StyledTableHeader2>
                    <StyledTableHeader2 sx={{ width: "300px" }}>
                      Months
                    </StyledTableHeader2>
                    <StyledTableHeader2 sx={{ width: "300px" }}>
                      Years
                    </StyledTableHeader2>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: "300px" }}>
                      Time until goal is reached
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      {yearsUntilExit
                        ? (parseFloat(yearsUntilExit) * 12).toFixed(0)
                        : 0}
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      {yearsUntilExit ? yearsUntilExit : 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid sm={4} lg={4} md={4} paddingLeft={"12%"} mt={10}>
            <Typography>Monthly Goal</Typography>
            <Typography sx={{ fontSize: "60px", color: "deepskyblue" }}>
              {monthlyGoal ? conversionThousandToK(monthlyGoal) : 0}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SavingPlan;
