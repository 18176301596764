import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { clearSnack } from "../../utils/utilFunctions";

export const LOADING_USER = "LOADING_USER";
export const STOP_LOADING_USER = "STOP_LOADING_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const OPEN_SNACK = "OPEN_SNACK";
export const CLEAR = "CLEAR";

export const INIT_STATES = "INIT_STATES";

export const iniateStatesUser = () => async (dispatch) => {
  dispatch({
    type: INIT_STATES,
  });
};

export const contactUsEmail = (payload) => async (dispatch) => {
  try {
    const resp = await axios.post(`${BASE_URL}/contactUsEmail`, payload, {
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    });
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "success",
        message: resp.data.message,
      },
    });
    clearSnack(dispatch);
  } catch (error) {
    console.log("erro2r", error);

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const updatePassword = (payload, navigate) => async (dispatch) => {
  try {
    const resp = await axios.post(`${BASE_URL}/updatePassword`, payload, {
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    });

    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "success",
        message: "Password updated!",
      },
    });
    clearSnack(dispatch);

    setTimeout(() => {
      navigate("/");
    }, 2000);
  } catch (error) {
    console.log("erro2r", error);
    dispatch({ type: STOP_LOADING_USER });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const verifyToken = (payload) => async (dispatch) => {
  try {
    const resp = await axios.post(`${BASE_URL}/verifyUser/`, payload);

    return {
      isValid: true,
      userId: resp.data.userId,
    };
  } catch (error) {
    console.log("error", error);
    dispatch({ type: STOP_LOADING_USER });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message,
      },
    });
    clearSnack(dispatch);
    return {
      isValid: false,
    };
  }
};

export const resetPswdEmail = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });

    const response = await axios.post(`${BASE_URL}/resetPassword`, payload);

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    dispatch({ type: STOP_LOADING_USER });
    clearSnack(dispatch);
  } catch (error) {
    console.log("erro2r", error);
    dispatch({ type: STOP_LOADING_USER });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const fetchUserById = (payload) => async (dispatch) => {
  const response = await axios.get(
    `${BASE_URL}/getUserById/${payload.userId}`,
    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    }
  );
  dispatch({
    type: LOGIN_SUCCESS,
    payload: response.data,
  });
};

export const singin = (value, navigate) => async (dispatch) => {
  // const navigate = useNavigate("");
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.post(`${BASE_URL}/login`, value, {
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    });
    //user details
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    localStorage.setItem("userEmail", response.data.data.email);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("id", response.data.data._id);
    localStorage.setItem("profileName", response.data.data.profileName);
    localStorage.setItem("logo", response.data.data.logo);
    localStorage.setItem("paymentStatus", response.data.data.payment_status);
    localStorage.setItem("cpcp_subscribed", response.data.data.cpcp_subscribed);
    localStorage.setItem("last_login_time", new Date());

    //redirect
    setTimeout(() => {
      if (
        response.data.data.payment_status ||
        response.data.data.cpcp_subscribed
      ) {
        navigate("/home");
      } else {
        navigate("/select");
      }
    }, 3000);

    clearSnack(dispatch);
  } catch (error) {
    //user reducer
    dispatch({
      type: "ERROR_LOGIN",
      message: error.response.data.message,
    });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });

    clearSnack(dispatch);
  }
};

export const register = (value, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });

    const response = await axios.post(`${BASE_URL}/addUser`, value);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    localStorage.setItem("userEmail", response.data.user.email);
    localStorage.setItem("token", response.data.user.token);
    localStorage.setItem("id", response.data.user._id);
    localStorage.setItem("paymentStatus", response.data.user.payment_status);
    localStorage.setItem("cpcp_subscribed", response.data.user.cpcp_subscribed);

    setTimeout(() => {
      if (
        response.data.user.payment_status ||
        response.data.user.cpcp_subscribed
      ) {
        navigate("/home");
      } else {
        navigate("/select");
      }
    }, 2000);

    clearSnack(dispatch);
  } catch (error) {
    //user reducer
    dispatch({
      type: "ERROR_LOGIN",
      message: error.response.data.message,
    });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const cancelSubscription = (value, navigate) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_URL}/cancelSubcription/${value}`);

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: res.data.message,
      },
    });
    clearSnack(dispatch);
    localStorage.setItem("paymentStatus", false);
    localStorage.setItem("cpcp_subscribed", false);

    setTimeout(() => {
      navigate("/select");
    }, 3000);
  } catch (error) {
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const updateProfileName = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/updateProfileName`, payload);
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: res.data.message,
      },
    });
    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message || "Something went wrong",
      },
    });
    clearSnack(dispatch);
  }
};

export const updateProfile = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/profile`, payload);

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: res.data.message,
      },
    });
    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message:
          "Invalid file type. Supported types: jpeg, jpg, png. Accepted file size is 5MB",
      },
    });
    clearSnack(dispatch);
  }
};

export const signout = (navigate) => async (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: "SET_DEFAULTVALUES_PDF" });
  setTimeout(() => {
    navigate("/");
  }, 2000);
};

export const deleteMember = (payload) => async (dispatch) => {
  const res = await axios.post(`${BASE_URL}/team/delete`, payload);

  dispatch({
    type: "OPEN_SNACK",
    payload: {
      snackType: "success",
      message: res.data.message,
    },
  });
  clearSnack(dispatch);
};

export const addTeamMember = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/team/add`, payload);
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "success",
        message: res.data.message,
      },
    });
    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "error",
        message: error?.response?.data?.message || "Something went wrong!",
      },
    });
    clearSnack(dispatch);
  }
};

export const fetchTeamMembers = () => async (dispatch) => {
  if (!localStorage.getItem("id")) return;
  try {
    const res = await axios.get(
      `${BASE_URL}/team/${localStorage.getItem("id")}`
    );

    // dispatch({
    //   type: OPEN_SNACK,
    //   payload: {
    //     snackType: "success",
    //     message: res.data.message,
    //   },
    // });

    return res.data.data;
  } catch (error) {
    // dispatch({
    //   type: OPEN_SNACK,
    //   payload: {
    //     snackType: "error",
    //     message: error?.response?.data?.message || "Something went wrong",
    //   },
    // });
    // clearSnack(dispatch);
  }
};

export const addClient = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/client/add`, payload);

    dispatch({
      type: "OPEN_STACK",
      payload: {
        snackType: "success",
        message: res.data.message,
      },
    });

    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "error",
        message: error?.response?.data?.message || "Something went wrong!",
      },
    });
    clearSnack(dispatch);
  }
};

export const fetchClients = () => async (dispatch) => {
  try {
    if (!localStorage.getItem("id")) return;
    const res = await axios.get(
      `${BASE_URL}/client/${localStorage.getItem("id")}`
    );
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteClient = (payload) => async (dispatch) => {
  const res = await axios.post(`${BASE_URL}/client/delete`, payload);

  dispatch({
    type: "OPEN_SNACK",
    payload: {
      snackType: "success",
      message: res.data.message,
    },
  });
  clearSnack(dispatch);
};

export const updateUser = (payload, userId) => async (dispatch) => {
  const resp = await axios.put(`${BASE_URL}/updateUserById/${userId}`, payload);

  return resp.data;
};
