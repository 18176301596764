const initialState = {
  pdfGraph: {
    images: {
      ownGoal: "",
      analysisOwnGoal: "",
      analysisIndustry: "",
      industryAvg: "",
      fmfInvenGraph: "",
      fmfInvenWhatIf: "",
      fmfServiceGraph: "",
      fmfServiceWhatIf: "",
      trend: "",
      exCntrl: "",
      debtEquity: "",
      ebitda: "",
      misMatch: "",
      cashFlowAct: "",
      comprtvRatio: "",
      simpleVal: "",
      estimateValSavingPlan: "",
      simpleValMilestone: "",

      currentRatioGuage: "",
      currentRatioChart: "",

      quickRatioGuage: "",
      quickRatioChart: "",

      debtEqRatioGuage: "",
      debtEqRatioChart: "",

      grossMarginGuage: "",
      grossMarginChart: "",

      netMarginGuage: "",
      netMarginChart: "",

      saleAssetsGuage: "",
      saleAssetsChart: "",

      ebidtaFDGuage: "",
      ebidtaFDChart: "",

      roaGuage: "",
      roaChart: "",

      roiGuage: "",
      roiChart: "",

      invTurnoverGuage: "",
      invTurnoverChart: "",

      arTurnoverGuage: "",
      arTurnoverChart: "",

      apTurnoverGuage: "",
      apTurnoverChart: "",

      dcohGuage: "",
      dcohChart: "",

      dscrGuage: "",
      dscrChart: "",
    },
    notes: {
      ownGoal: "",
      industryAvg: "",
      fmfInvenWhatIf: "",
      fmfServiceWhatIf: "",
      trend: "",
      exCntrl: "",
      debtEquity: "",
      ebitda: "",
      misMatch: "",
      cashFlowAct: "",
      comprtvRatio: "",
      simpleVal: "",
      estimateValSavingPlan: "",
      currentRatioGuage: "",
      currentRatioChart: "",

      quickRatioGuage: "",
      quickRatioChart: "",

      debtEqRatioGuage: "",
      debtEqRatioChart: "",

      grossMarginGuage: "",
      grossMarginChart: "",

      netMarginGuage: "",
      netMarginChart: "",

      saleAssetsGuage: "",
      saleAssetsChart: "",

      ebidtaFDGuage: "",
      ebidtaFDChart: "",

      roaGuage: "",
      roaChart: "",

      roiGuage: "",
      roiChart: "",

      invTurnoverGuage: "",
      invTurnoverChart: "",

      arTurnoverGuage: "",
      arTurnoverChart: "",

      apTurnoverGuage: "",
      apTurnoverChart: "",

      dcohGuage: "",
      dcohChart: "",

      dscrGuage: "",
      dscrChart: "",
    },
  },

  notesDates: {
    ownGoal: "",
    industryAvg: "",
    fmfInvenWhatIf: "",
    fmfServiceWhatIf: "",
    trend: "",
    exCntrl: "",
    debtEquity: "",
    ebitda: "",
    misMatch: "",
    cashFlowAct: "",
    comprtvRatio: "",
    simpleVal: "",
    estimateValSavingPlan: "",

    currentRatioGuage: "",
    currentRatioChart: "",

    quickRatioGuage: "",
    quickRatioChart: "",

    debtEqRatioGuage: "",
    debtEqRatioChart: "",

    grossMarginGuage: "",
    grossMarginChart: "",

    netMarginGuage: "",
    netMarginChart: "",

    saleAssetsGuage: "",
    saleAssetsChart: "",

    ebidtaFDGuage: "",
    ebidtaFDChart: "",

    roaGuage: "",
    roaChart: "",

    roiGuage: "",
    roiChart: "",

    invTurnoverGuage: "",
    invTurnoverChart: "",

    arTurnoverGuage: "",
    arTurnoverChart: "",

    apTurnoverGuage: "",
    apTurnoverChart: "",

    dcohGuage: "",
    dcohChart: "",

    dscrGuage: "",
    dscrChart: "",
  },

  graphLoading: false,
};

const pdfGraphReducers = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DEFAULTVALUES_PDF":
      return {
        ...state,
        ...initialState,
      };

    case "SAVE_NOTES_PDF":
      return {
        ...state,
        pdfGraph: {
          ...state.pdfGraph,
          notes: {
            ...state.pdfGraph.notes,
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case "UPDATE_PDF_DATA":
      return {
        ...state,
        pdfGraph: {
          images: {
            ...state.pdfGraph.images,
            ...action.payload.images,
          },
          notes: {
            ...state.pdfGraph.notes,
            ...action.payload.notes,
          },
        },
      };

    case "UPDATE_NOTES_DATE_PDF":
      return {
        ...state,
        notesDates: {
          ...state.notesDates,
          [action.payload.key]: action.payload.value,
        },
      };

    case "STOP_GRAPH_LOADING":
      return {
        ...state,
        graphLoading: false,
      };

    case "GRAPH_LOADING":
      return {
        ...state,
        graphLoading: true,
      };

    default:
      return state;
  }
};

export default pdfGraphReducers;
