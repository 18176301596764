const initialState = {
  industryAvgGraphNotes: {},
  ownGoalGraphNotes: {},
  comparativeRatioNotes: {},
  inventCompFMFNotes: {},
  serviceCompFMFNotes: {},
  trendsNotes: {},
  expenseControlNotes: {},
  debtEqRatioNotes: {},
  ebidtaNotes: {},
  cashFlowActvtyNotes: {},
  simpleValNotes: {},
  startWithEndNotes: {},

  currentRatioNotes: {},
  quickRatioNotes: {},

  noteLoading: false,
};

const notesReducers = (state = initialState, action) => {
  switch (action.type) {
    case "NOTE_LOADING":
      return {
        ...state,
        noteLoading: true,
      };

    case "NOTE_LOADING_STOP":
      return {
        ...state,
        noteLoading: false,
      };

    case "INDUS_AVG_NOTES":
      return {
        ...state,
        industryAvgGraphNotes: action.payload,
      };

    case "COMP_RATIO_NOTES":
      return {
        ...state,
        comparativeRatioNotes: action.payload,
      };

    case "OWN_GRAPH_NOTES":
      return {
        ...state,
        ownGoalGraphNotes: action.payload,
      };

    case "INV_GRAPH_NOTES":
      return {
        ...state,
        inventCompFMFNotes: action.payload,
      };

    case "SERVICE_GRAPH_NOTES":
      return {
        ...state,
        serviceCompFMFNotes: action.payload,
      };

    case "TRENDS_NOTES":
      return {
        ...state,
        trendsNotes: action.payload,
      };
    case "EXPENSE_CNTRL_NOTES":
      return {
        ...state,
        expenseControlNotes: action.payload,
      };

    case "DEBY_EQ_NOTES":
      return {
        ...state,
        debtEqRatioNotes: action.payload,
      };

    case "EBIDTA_NOTES":
      return {
        ...state,
        ebidtaNotes: action.payload,
      };

    case "MIS_MATCH_NOTES":
      return {
        ...state,
        misMatchNotes: action.payload,
      };

    case "CASH_FLOW_NOTES":
      return {
        ...state,
        cashFlowActvtyNotes: action.payload,
      };

    case "SIMPLE_VAL_NOTES":
      return {
        ...state,
        simpleValNotes: action.payload,
      };

    case "START_WITH_END_NOTES":
      return {
        ...state,
        startWithEndNotes: action.payload,
      };

    case "CURRENT_RATIO_NOTES":
      return {
        ...state,
        currentRatioNotes: action.payload,
      };

    case "QUICK_RATIO_NOTES":
      return {
        ...state,
        quickRatioNotes: action.payload,
      };

    case "DEBT_EQUITY_NOTES":
      return {
        ...state,
        debtEqRatioNotes: action.payload,
      };

    case "GROSS_MARGIN_NOTES":
      return {
        ...state,
        grossMarginNotes: action.payload,
      };

    case "NET_MARGIN_NOTES":
      return {
        ...state,
        netMarginNotes: action.payload,
      };

    case "SALE_ASSETS_NOTES":
      return {
        ...state,
        saleAssetsNotes: action.payload,
      };

    case "EBIDTA_FD_NOTES":
      return {
        ...state,
        ebidtaFDNotes: action.payload,
      };

    case "ROA_NOTES":
      return {
        ...state,
        roaNotes: action.payload,
      };

    case "ROI_NOTES":
      return {
        ...state,
        roiNotes: action.payload,
      };

    case "INV_TURNOVER_NOTES":
      return {
        ...state,
        invTurnoverNotes: action.payload,
      };

    case "AR_TURNOVER_NOTES":
      return {
        ...state,
        arTurnoverNotes: action.payload,
      };

    case "AP_TURNOVER_NOTES":
      return {
        ...state,
        apTurnoverNotes: action.payload,
      };

    case "DCOH_NOTES":
      return {
        ...state,
        dcohNotes: action.payload,
      };

    case "DSCR_NOTES":
      return {
        ...state,
        dscrNotes: action.payload,
      };

    case "INIT_STATES":
      return { ...state, ...initialState };

    default:
      return state;
  }
};
export default notesReducers;
