import { React, useEffect, useState } from "react";
import { Grid, Box, Typography, Modal, Button } from "@mui/material";
import companyLogo from "../../assets/company_logo.png";

const MobileWarning = () => {
  return (
    <Grid className="mobile_message">
      <Grid>
        <img className="mobile_logo" src={companyLogo} alt="Company Logo" />
      </Grid>
      <Typography sx={{ marginTop: 3, fontSize: "20px", fontWeight: "700" }}>
        Optimized for Larger Screens
      </Typography>
      <Typography px={5} variant="h6" sx={{ marginTop: 3 }}>
        The Clear Path to Cash app is designed for the best experience on a
        desktop or larger screen.
      </Typography>
      <Typography px={5} variant="h6" sx={{ marginTop: 3 }}>
        For full functionality and an optimal user experience, please open the
        app on a desktop or tablet.
      </Typography>
      <Button className="got_it_button" href="https://cashflowmike.com">
        Got it!
      </Button>
    </Grid>
  );
};

export default MobileWarning;
