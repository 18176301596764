import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { GRAPH_TOOLTIP_SETTINGS } from "../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function CashFlowActivityPatternChart({ cashFlow, range }) {
  const options = {
    responsive: true,
    display: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
      tooltip: {
        enabled: true,
        titleFont: {
          size: GRAPH_TOOLTIP_SETTINGS.titleFont,
        },
        bodyFont: {
          size: GRAPH_TOOLTIP_SETTINGS.bodyFont,
        },
        padding: GRAPH_TOOLTIP_SETTINGS.padding,
      },
    },
  };
  const labels = range;

  const data = {
    labels,
    datasets: [
      {
        label: "Operating Activities",
        data: cashFlow.operatingActivities,
        borderColor: "#4caf50",
        backgroundColor: "#80ca83",
      },
      {
        label: "Investing Activities",
        data: cashFlow.investingActivities,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Financing Activities",
        data: cashFlow.financingActivities,
        borderColor: "#0c0f7c",
        backgroundColor: "#4447ba",
      },
    ],
  };
  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
}
