// src/HorizontalGauge.js
import React from "react";
import "./horizontalGuage/HorizontalGauge.css";

function compareNumbersPercentage(a, b) {
  var difference = a - b;
  var percentage = (difference / b) * 100;

  if (difference > 0) {
    // return "Number a is " + percentage.toFixed(2) + "% greater than number b";
    return percentage.toFixed(2);
  } else if (difference < 0) {
    // return (
    //   "Number a is " +
    //   Math.abs(percentage).toFixed(2) +
    //   "% smaller than number b"
    // );
    return Math.abs(percentage).toFixed(2);
  } else {
    return "Number a is equal to number b";
  }
}

function findClosestNumber(arr, num) {
  const numb = parseFloat(num);

  let closest = arr[0]; // Assume the first element is the closest initially
  let closestIndex = 0;
  let roundOffVal;
  arr.forEach(function (element, i) {
    if (Math.abs(element - numb) <= Math.abs(closest - numb)) {
      closest = element;
      closestIndex = i;
      if (numb == closest) {
        roundOffVal = 0;
      } else if (numb > closest) {
        roundOffVal = 1.5;
      } else if (numb < closest) roundOffVal = -1.5;
    }
  });

  return { closestIndex, roundOffVal };
}

const HorizontalGauge = ({ currentRatioScale, value, maxValue, numMarks }) => {
  const percentage = (value / maxValue) * 100;

  const indutsryAvgVal = currentRatioScale[6];

  const { closestIndex, roundOffVal } = findClosestNumber(
    currentRatioScale,
    value
  );

  const diffPerc = compareNumbersPercentage(currentRatioScale[6], value);
  // const pointerPosition = `${percentage + 2}%`;

  const percVal = (closestIndex / numMarks) * 100 + 1.25 + diffPerc;

  const pointerPosition = `${parseFloat(
    parseInt(percVal) + roundOffVal + 0.5
  ).toFixed(2)}%`;

  const marks = [];

  for (let i = 0; i <= currentRatioScale?.length; i++) {
    const markValue = currentRatioScale[i];
    const position = (i / numMarks) * 100;

    marks.push(
      <div
        key={i}
        className={`mark ${i % 2 === 0 ? "show-label" : ""}`}
        style={{ left: `${position + 2}%` }}
      >
        <span className="mark-label">{markValue}</span>
      </div>
    );
  }

  const handleHover = () => {
    // Display tooltip
    const tooltip = document.getElementById("tooltip");
    tooltip.style.display = "block";
  };

  // Function to handle tooltip hide
  const handleLeave = () => {
    // Hide tooltip
    const tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
  };

  return (
    <div className="horizontal-gauge">
      {/* <div className="gauge-fill" style={{ width: `${percentage}%` }}>
        <div className="value-label">{value}</div>
      </div> */}
      <div
        className="pointer"
        style={{ left: pointerPosition }}
        onMouseEnter={handleHover} // Show tooltip on hover
        onMouseLeave={handleLeave} // Hide tooltip on leave
      >
        <div className="pointer-line"></div>{" "}
        {/* Line from pointer to main line */}
        {/* <div className="arrow-up"></div> */}
      </div>
      <div className="gauge-line"></div>
      <div className="tooltip" id="tooltip">
        {value}
      </div>
      <div className="scale">{marks}</div> {/* Wrap marks in a container */}
    </div>
  );
};

export default HorizontalGauge;
