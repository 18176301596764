import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { clearSnack } from "../../utils/utilFunctions";

export const FETCH_START_WITH_END = "FETCH_START_WITH_END";
export const START_WITH_END_LOADING = "START_WITH_END_LOADING";
export const START_WITH_END_ERROR = "START_WITH_END_ERROR";
export const ADD_TRANSFERABLE_VALUE = "ADD_TRANSFERABLE_VALUE";
export const STOP_LOADING = "STOP_LOADING";

export const INIT_STATES = "INIT_STATES";
export const EMPTY_STATE = "EMPTY_STATE";

export const OPEN_SNACK = "OPEN_SNACK";
export const CLEAR_SNACK = "CLEAR_SNACK";

// Action to initialize states
export const initStatesStartWithEnd = () => async (dispatch) => {
  dispatch({ type: INIT_STATES });
};

// Fetch StartWithEnd details by companyId
export const fetchStartWithEnd = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: START_WITH_END_LOADING });

    const response = await axios.get(
      `${BASE_URL}/startWithEnd/company/${companyId}`
    );

    dispatch({ type: STOP_LOADING });

    return response;

    dispatch({
      type: FETCH_START_WITH_END,
      payload: response.data,
    });

    clearSnack(dispatch);
    return;
  } catch (error) {
    dispatch({
      type: START_WITH_END_ERROR,
      payload: error?.response?.data?.message || "Error fetching data",
    });
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message || "Error fetching data",
      },
    });
    clearSnack(dispatch);
  }
};

// Add new StartWithEnd data (Create)
export const addStartWithEnd = (payload) => async (dispatch) => {
  try {
    dispatch({ type: START_WITH_END_LOADING });

    const response = await axios.post(`${BASE_URL}/startWithEnd`, payload);

    dispatch({ type: STOP_LOADING });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    dispatch({ type: STOP_LOADING });

    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: START_WITH_END_ERROR,
      payload: error?.response?.data?.message || "Error adding data",
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message || "Error adding data",
      },
    });
    clearSnack(dispatch);
  }
};

// Update StartWithEnd data (excluding companyId and userId)
export const updateStartWithEnd = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: START_WITH_END_LOADING });

    const response = await axios.put(`${BASE_URL}/startWithEnd/${id}`, payload);

    dispatch({ type: STOP_LOADING });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    clearSnack(dispatch);
    return response;
  } catch (error) {
    dispatch({
      type: START_WITH_END_ERROR,
      payload: error?.response?.data?.message || "Error updating data",
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message || "Error updating data",
      },
    });
    clearSnack(dispatch);
  }
};

export const updateTransferableValue =
  (companyId, payload) => async (dispatch) => {
    dispatch({ type: START_WITH_END_LOADING });

    const response = await axios.put(
      `${BASE_URL}/startWithEnd/${companyId}/transferableValue`,
      payload
    );

    dispatch({
      type: ADD_TRANSFERABLE_VALUE,
      payload: response.data.data.transferableValues,
    });

    dispatch({ type: STOP_LOADING });

    return response.data.data.transferableValues;
  };

export const updateScenario = (compId, payload) => async (dispatch) => {
  try {
    dispatch({ type: START_WITH_END_LOADING });
    const respo = await axios.put(
      `${BASE_URL}/startWithEnd/updateScen/${compId}`,
      payload
    );
    dispatch({ type: STOP_LOADING });
    return respo;
  } catch (error) {
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "error",
        message: "Something went wrong",
      },
    });
  }
};

export const scenDateCheck = (compId, payload) => async (dispatch) => {
  dispatch({ type: START_WITH_END_LOADING });
  const response = await axios.post(
    `${BASE_URL}/startWithEnd/dateCheck/${compId}`,
    payload
  );
  dispatch({ type: STOP_LOADING });
  return response;
};

// Push new transferableValue in the array
export const pushTransferableValue =
  (companyId, payload) => async (dispatch) => {
    try {
      dispatch({ type: START_WITH_END_LOADING });

      const response = await axios.post(
        `${BASE_URL}/startWithEnd/${companyId}/transferableValue`,
        payload
      );

      dispatch({
        type: ADD_TRANSFERABLE_VALUE,
        payload: response.data,
      });

      //   dispatch({
      //     type: OPEN_SNACK,
      //     payload: {
      //       snackType: "success",
      //       message: "Transferable value added successfully",
      //     },
      //   });
      // clearSnack(dispatch);
    } catch (error) {
      dispatch({
        type: START_WITH_END_ERROR,
        payload:
          error?.response?.data?.message || "Error adding transferable value",
      });

      dispatch({
        type: OPEN_SNACK,
        payload: {
          snackType: "error",
          message:
            error?.response?.data?.message || "Error adding transferable value",
        },
      });
      clearSnack(dispatch);
    }
  };

// Empty state action
export const emptyStartWithEndState = () => async (dispatch) => {
  dispatch({ type: EMPTY_STATE });
};

export const fetchLatestSimpleVal = (compId) => async () => {
  return await axios.get(`${BASE_URL}/simpleVal/getLatest/${compId}`);
};

export const deleteScenario = (scenId) => async () => {
  return await axios.delete(`${BASE_URL}/startWithEnd/${scenId}`);
};
