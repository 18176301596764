import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import Analytics from "../../assets/analytics-icon.svg";

export default function HiddenCashAnalysis({ analysis }) {
  return (
    <div
      style={{
        backgroundColor: "#EBF8FC",
        padding: "20px",
        borderRadius: "20px",
      }}
    >
      <Typography>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
          <span
            style={{
              fontWeight: "bolder",
              fontSize: 18,
            }}
          >
            Analysis
          </span>
        </span>
        <span
          style={{
            fontSize: 16,
            color: "#606060",
          }}
        >
          <span
            style={{
              fontWeight: "800",
            }}
          >
            • Sales To Assets
          </span>{" "}
          <Typography style={{ marginLeft: 10, marginBottom: 10 }}>
            {analysis.salesToAssestsAnlys}
          </Typography>
        </span>
        <span
          style={{
            fontSize: 16,
            color: "#606060",
          }}
        >
          <span
            style={{
              fontWeight: "800",
            }}
          >
            • Gross Profit
          </span>{" "}
          <Typography
            style={{
              marginLeft: 10,
              marginBottom: 10,
              whiteSpace: "pre-wrap",
              lineHeight: "1.5",
            }}
          >
            {analysis.grossProfitAnlys}
          </Typography>
        </span>
        <span
          style={{
            fontSize: 16,
            color: "#606060",
          }}
        >
          <span
            style={{
              fontWeight: "800",
            }}
          >
            • Operating Expense
          </span>{" "}
          <Typography style={{ marginLeft: 10, marginBottom: 10 }}>
            {analysis.operatingExpenseAnlys}
          </Typography>
        </span>
        <span
          style={{
            fontSize: 16,
            color: "#606060",
          }}
        >
          <span
            style={{
              fontWeight: "800",
            }}
          >
            • Inventory:
          </span>{" "}
          <Typography style={{ marginLeft: 10, marginBottom: 10 }}>
            {analysis.invtryTurnoverAnlys}
          </Typography>
        </span>
        <span
          style={{
            fontSize: 16,
            color: "#606060",
          }}
        >
          <span
            style={{
              fontWeight: "800",
            }}
          >
            • Account Receivable
          </span>{" "}
          <Typography style={{ marginLeft: 10, marginBottom: 10 }}>
            {analysis.arTurnoverAnlys}
          </Typography>
        </span>
        <span
          style={{
            fontSize: 16,
            color: "#606060",
          }}
        >
          <span
            style={{
              fontWeight: "800",
            }}
          >
            • Account Payable
          </span>{" "}
          <Typography style={{ marginLeft: 10 }}>
            {analysis.apTurnoverAnlys}
          </Typography>
        </span>
      </Typography>
    </div>
  );
}
