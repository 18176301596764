import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "../../layout";
import { CardWrapper } from "../../components";
import {
  Grid,
  Typography,
  Tooltip,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Select,
  MenuItem,
  Chip,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  editActionLog,
  getActionLogByCompany,
} from "../../redux/actions/actionLog.js";
import {
  ACTION_LOG_CATEGORIES,
  // ACTION_LOG_STATUS_OUTCOME,
  CASH_FLOW_ACTIVITY_PATTERN_LOG,
  DEBT_TO_EQUITY_RATIO_HR_LOG,
  EBITDA_HR_LOG,
  EXPENSE_CONTROL_LOG,
  MISMATCHED_FINANC_LOG,
  QUICK_RATIO_HR_LOG,
  SIMPLE_VALUATION_LOG,
  TRENDS_LOG,
  START_WITH_END_LOG,
  CURRENT_RATIO_FD_LOG,
  QUICK_RATIO_FD_LOG,
  DEBT_TO_EQUITY_RATIO_FD_LOG,
  GROSS_MARGIN_FD_LOG,
  NET_MARGIN_FD_LOG,
  SALES_TO_ASSETS_FD_LOG,
  EBITDA_FD_LOG,
  ROA_FD_LOG,
  ROI_FD_LOG,
  INVENTORY_TURNOVER_FD_LOG,
  AR_TURNOVER_FD_LOG,
  AP_TURNOVER_FD_LOG,
  DAYS_CASH_ON_HAND_FD_LOG,
  DSCR_FD_LOG,
  FMF_LOG,
  YOU_VS_INDUSTRY_GOAL_LOG,
  YOU_VS_OWNER_GOAL_LOG,
} from "../../utils/constants.js";
import { handleShowMetric } from "../../utils/utilFunctions.js";
import CircularProgress from "@mui/material/CircularProgress";
import { handlInfo } from "../../utils/utilFunctions.js";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import AddActionModal from "../../components/add-action-modal";

const CompanyLogo = ({ companyName }) => {
  // Get the first letter of the company name
  const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

  // Style for the red circle
  const circleStyle = {
    width: "50px", // Set your desired logo size
    height: "50px", // Set your desired logo size
    borderRadius: "50%",
    backgroundColor: "#b7959b",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "1.5em",
    marginRight: "10px",
  };

  return <div style={circleStyle}>{firstLetter}</div>;
};

const ActionLogs = () => {
  const { company } = useSelector((state) => state.companyDetailReducer);

  const { user } = useSelector((state) => state.userReducer);
  const { actionLogs } = useSelector((state) => state.actionLogReducer);
  const { selectedCompany } = useSelector((state) => state.companyReducer);
  const [filterType, setFilterType] = useState("all");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const [loadingData, setLoadingData] = useState({
    index: 0,
    type: "",
    isLoading: false,
  });

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingField, setEditingField] = useState({
    index: null,
    field: null,
    value: "",
    logId: null,
  });

  const [editedValues, setEditedValues] = useState({});

  const [reload, setReload] = useState(false);
  const [logData, setLogData] = useState({
    actionDescription: "",
    expectedResult: "",
    dateOfAction: "",
    category: "",
    status: "",
  });
  const [selectedActionLogId, setSelectedActionLogId] = useState();

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("actionLogs", actionLogs);
  }, [actionLogs]);

  useEffect(() => {
    const payload = {
      companyId: selectedCompany._id,
    };
    dispatch(getActionLogByCompany(payload));
  }, [dispatch, selectedCompany._id]);

  // Get unique types from actionLogs
  const uniqueTypes = [...new Set(actionLogs?.map((log) => log.type))];

  // Filter logs based on selected type
  const filteredLogs =
    filterType === "all"
      ? actionLogs
      : actionLogs?.filter((log) => log.type === filterType);

  const handleUpdateLog = async (logId, type, value) => {
    console.log("value", value);

    setLoadingData({
      ...loadingData,
      index: editingField.index,
      type,
      isLoading: true,
    });
    const payload = {
      [type]: value,
    };
    await dispatch(editActionLog(logId, payload));
    dispatch(getActionLogByCompany({ companyId: selectedCompany._id }));
    setLoadingData({ ...loadingData, isLoading: false, index: 0, type: "" });
    setEditModalOpen(false);
    setEditingField({ index: null, field: null, value: "", logId: null });
    setEditedValues({});
  };

  const handleOpenEditModal = (index, field, value, logId) => {
    setEditingField({
      index,
      field,
      value: value || "",
      logId,
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setEditingField({ index: null, field: null, value: "", logId: null });
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortedLogs = (logs) => {
    if (!sortConfig.key) return logs;

    return [...logs].sort((a, b) => {
      if (sortConfig.key === "date") {
        return sortConfig.direction === "ascending"
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      }

      // Get values for comparison
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle null/undefined/empty values for status specifically
      if (sortConfig.key === "status") {
        aValue = aValue || ""; // Convert null/undefined to empty string
        bValue = bValue || ""; // Convert null/undefined to empty string

        const statusOrder = {
          Successful: 2,
          "In Progress": 3,
          Pending: 4,
          Abandoned: 1,
          Failed: 5,
          "": 6, // Empty string will be sorted last
        };

        console.log("stastusOrder", statusOrder);
        console.log("aVaasdlue", aValue);

        const aOrder = statusOrder[aValue] || 5; // Default to 5 if status not found
        const bOrder = statusOrder[bValue] || 5; // Default to 5 if status not found

        return sortConfig.direction === "ascending"
          ? aOrder - bOrder
          : bOrder - aOrder;
      }

      if (sortConfig.key === "type") {
        aValue = aValue || ""; // Convert null/undefined to empty string
        bValue = bValue || ""; // Convert null/undefined to empty string

        const statusOrder = {
          // HOME RUN
          [TRENDS_LOG]: 3,
          [QUICK_RATIO_HR_LOG]: 3,
          [EXPENSE_CONTROL_LOG]: 3,
          [DEBT_TO_EQUITY_RATIO_HR_LOG]: 3,
          [EBITDA_HR_LOG]: 3,
          [MISMATCHED_FINANC_LOG]: 3,
          [CASH_FLOW_ACTIVITY_PATTERN_LOG]: 3,

          // SIMPLE VALUATION
          [SIMPLE_VALUATION_LOG]: 5,

          // START WITH END IN MIND
          [START_WITH_END_LOG]: 5,

          // FINANCIAL DOCTOR
          [CURRENT_RATIO_FD_LOG]: 1,
          [QUICK_RATIO_FD_LOG]: 1,
          [DEBT_TO_EQUITY_RATIO_FD_LOG]: 1,
          [GROSS_MARGIN_FD_LOG]: 1,
          [NET_MARGIN_FD_LOG]: 1,
          [SALES_TO_ASSETS_FD_LOG]: 1,
          [EBITDA_FD_LOG]: 1,
          [ROA_FD_LOG]: 1,
          [ROI_FD_LOG]: 1,
          [INVENTORY_TURNOVER_FD_LOG]: 1,
          [AR_TURNOVER_FD_LOG]: 1,
          [AP_TURNOVER_FD_LOG]: 1,
          [DAYS_CASH_ON_HAND_FD_LOG]: 1,
          [DSCR_FD_LOG]: 1,

          // FAST MONERY FORMULA
          [FMF_LOG]: 2,

          // MINING YOUR BUSINESS
          [YOU_VS_OWNER_GOAL_LOG]: 4,
          [YOU_VS_INDUSTRY_GOAL_LOG]: 4,

          "": 6,
        };

        const aOrder = statusOrder[aValue] || 5; // Default to 5 if status not found
        const bOrder = statusOrder[bValue] || 5; // Default to 5 if status not found

        return sortConfig.direction === "ascending"
          ? aOrder - bOrder
          : bOrder - aOrder;
      }

      // For other string columns
      if (aValue === null || aValue === undefined) aValue = "";
      if (bValue === null || bValue === undefined) bValue = "";

      return sortConfig.direction === "ascending"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });
  };

  let sortedLogs = getSortedLogs(filteredLogs || []);

  useEffect(() => {
    sortedLogs = getSortedLogs(filteredLogs || []);
  }, [actionLogs]);

  return (
    <React.Fragment>
      <Layout title="Action Logs Report">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CompanyLogo companyName={company.companyName} />
            <Typography className="financial_data_heading">
              {company?.companyName}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#F8F8FF",
                      color: "gray",
                      paddingY: "10px",
                      paddingX: "20px",
                    },
                  },
                }}
                title={
                  <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                    {company?.NAICStitle}
                  </pre>
                }
                placement="bottom"
              >
                <Typography
                  className="financial_data_right_heading"
                  sx={{ cursor: "pointer" }}
                >
                  NAICS Code: {company?.NAICScode}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 10, width: "100%" }} />
        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            alignItems: "center",
          }}
        >
          <Typography className="hidden_cash_subTitle">
            Action Log Report
            <InfoOutlinedIcon
              onClick={() => handlInfo("actionLog")}
              className="infor_icon"
            />
          </Typography>
        </Grid>
        <Grid style={{ height: 10, width: "100%" }} />
        <CardWrapper>
          <Grid
            sx={{
              mb: 2,
              width: "100% !important",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              disabled={sortedLogs?.length === 0}
              variant="contained"
              onClick={() => {
                const headers = [
                  "Date",
                  "Status",
                  "Calculation",
                  "Description",
                  "Expected Result",
                ];

                const escapeCSVValue = (value) => {
                  if (value === null || value === undefined) return '""'; // Handle null/undefined values
                  const stringValue = String(value).replace(/"/g, '""'); // Escape double quotes
                  return `"${stringValue}"`; // Wrap the value in double quotes
                };

                const csvData = sortedLogs.map((log) => [
                  escapeCSVValue(new Date(log?.date).toLocaleDateString()),
                  escapeCSVValue(log?.status || ""),
                  escapeCSVValue(
                    ACTION_LOG_CATEGORIES[log?.category]?.name || ""
                  ),
                  escapeCSVValue(log?.description || ""),
                  escapeCSVValue(log?.expectedResult || ""),
                ]);

                // Add BOM for proper encoding and format headers with quotes
                const BOM = "\uFEFF";
                const csvContent =
                  BOM +
                  [headers.map(escapeCSVValue), ...csvData]
                    .map((row) => row.join(","))
                    .join("\n");

                const blob = new Blob([csvContent], {
                  type: "text/csv;charset=utf-8",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${company?.companyName}_report.csv`;
                a.click();
                window.URL.revokeObjectURL(url);
              }}
            >
              Download CSV
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#201b5b",
                textTransform: "capitalize",
              }}
              onClick={() => setOpen(true)}
            >
              + Add Action
            </Button>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => requestSort("date")}
                  style={{ cursor: "pointer", minWidth: 90 }}
                >
                  <b>
                    Date{" "}
                    <SwapVertIcon sx={{ color: "#5a5a5a", fontSize: "20px" }} />
                  </b>
                </TableCell>
                <TableCell
                  onClick={() => requestSort("status")}
                  style={{ cursor: "pointer", minWidth: 90 }}
                >
                  <b>
                    Status{" "}
                    <SwapVertIcon sx={{ color: "#5a5a5a", fontSize: "20px" }} />
                  </b>
                </TableCell>
                <TableCell
                  onClick={() => requestSort("type")}
                  style={{ cursor: "pointer", minWidth: 90 }}
                >
                  <b>
                    Calculation{" "}
                    <SwapVertIcon sx={{ color: "#5a5a5a", fontSize: "20px" }} />
                  </b>
                </TableCell>
                <TableCell>
                  <b>Description</b>
                </TableCell>
                <TableCell>
                  <b>Expected Result</b>
                </TableCell>
                {/* <TableCell>
                  <b>Outcome</b>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedLogs && sortedLogs.length > 0 ? (
                sortedLogs.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {new Date(log?.date).toLocaleDateString("en-US", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {loadingData.index === index &&
                          loadingData.type === "status" &&
                          loadingData.isLoading && (
                            <CircularProgress
                              color="inherit"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginLeft: "5%",
                              }}
                            />
                          )}

                        <Select
                          value={log?.status || ""}
                          onChange={(e) =>
                            handleUpdateLog(log?._id, "status", e.target.value)
                          }
                          size="small"
                          sx={{
                            minWidth: 130,
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none", // Remove the border
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none", // Remove the border on focus as well
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                            },
                          }}
                          name="status"
                        >
                          <MenuItem value="Successful">
                            <Chip
                              label="Successful"
                              color="success"
                              size="small"
                            />
                          </MenuItem>
                          <MenuItem value="In Progress">
                            <Chip
                              label="In Progress"
                              color="warning"
                              size="small"
                            />
                          </MenuItem>
                          <MenuItem value="Pending">
                            <Chip label="Pending" color="info" size="small" />
                          </MenuItem>
                          <MenuItem value="Abandoned">
                            <Chip
                              label="Abandoned"
                              color="error"
                              size="small"
                            />
                          </MenuItem>
                          <MenuItem value="Failed">
                            <Chip label="Failed" color="error" size="small" />
                          </MenuItem>
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell>
                      {handleShowMetric(log?.type)}
                      {/* {ACTION_LOG_CATEGORIES[log?.category]?.name} */}
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {loadingData.index === index &&
                          loadingData.type === "description" &&
                          loadingData.isLoading && (
                            <CircularProgress
                              color="inherit"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginLeft: "5%",
                              }}
                            />
                          )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography style={{ flexGrow: 1 }}>
                            {log?.description || ""}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpenEditModal(
                                index,
                                "description",
                                log?.description,
                                log?._id
                              )
                            }
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {loadingData.index === index &&
                          loadingData.type === "expectedResult" &&
                          loadingData.isLoading && (
                            <CircularProgress
                              color="inherit"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginLeft: "5%",
                              }}
                            />
                          )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography style={{ flexGrow: 1 }}>
                            {log?.expectedResult || ""}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpenEditModal(
                                index,
                                "expectedResult",
                                log?.expectedResult,
                                log?._id
                              )
                            }
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      {ACTION_LOG_STATUS_OUTCOME[log?.status]}
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="body1" sx={{ py: 2 }}>
                      No action logs found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <Dialog
            open={editModalOpen}
            onClose={handleCloseEditModal}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Edit{" "}
              {editingField.field === "description"
                ? "Description"
                : "Expected Result"}
            </DialogTitle>
            <DialogContent
              sx={{
                overflowX: "hidden",
                overflowY: "hidden",
              }}
            >
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                multiline
                rows={8}
                value={editingField.value}
                onChange={(e) =>
                  setEditingField({ ...editingField, value: e.target.value })
                }
                sx={{
                  border: "1px solid #bbbbbbde",
                  borderRadius: "8px",
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditModal}>Cancel</Button>
              <Button
                onClick={() =>
                  handleUpdateLog(
                    editingField.logId,
                    editingField.field,
                    editingField.value
                  )
                }
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </CardWrapper>
      </Layout>
      <AddActionModal
        open={open}
        setOpen={setOpen}
        selectedCompany={selectedCompany}
        user={user}
        logData={logData}
        setLogData={setLogData}
        selectedActionLogId={selectedActionLogId}
        setSelectedActionLogId={setSelectedActionLogId}
        setReload={setReload}
      />
    </React.Fragment>
  );
};

export default ActionLogs;
