import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import currency_symbols from "../../utils/currency";
import { handleSign } from "../../utils/utilFunctions";

import { Typography, Select, MenuItem, Button, Grid } from "@mui/material";

import { useEffect } from "react";
import SnackBar from "../../components/snackBar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from "chart.js";
import { GRAPH_TOOLTIP_SETTINGS } from "../../utils/constants";

const MilestonesLineGraph = ({
  graphDate,
  simpleValData,
  dummySimpleVal,
  setDummySimpleVal,
  setIsGraphUpdate,
  isGraphUpdate,
  handleSelectedMarkerVals,
  totalEquity,
}) => {
  const dispatch = useDispatch();

  const { selectedCompany } = useSelector((state) => state.companyReducer);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    x: 0,
    y: 0,
    text: "",
  });
  const Tooltip = ({ x, y, text, isVisible }) => {
    const tooltipStyle = {
      display: isVisible ? "block" : "none",
      position: "absolute",
      left: x,
      top: y,
      fontSize: "25px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "#fff",
      padding: "5px",
      borderRadius: "5px",
    };

    return <div style={tooltipStyle}>{text}</div>;
  };

  const handleMouseEnter = (x, y, name) => {
    setTooltipPosition({ x, y, text: name });
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const { openSnackbar, snackType, message } = useSelector(
    (state) => state.snackbarReducer
  );

  const dummySimpleValRef = useRef(null);

  const chartRef = useRef(null);

  useEffect(() => {
    dummySimpleValRef.current = dummySimpleVal;
  }, [dummySimpleVal]);

  useEffect(() => {
    refreshChartData();

    setReloadChart(true);
    setTimeout(() => setReloadChart(false), 1000);
  }, [graphDate]);

  const initialData = {
    // labels: graphDate?.datesArray,
    labels: graphDate?.datesArray,
    datasets: [
      {
        label: "Market Value",
        data: graphDate?.pointsArray,
        borderColor: "rgba(75, 192, 192, 1)",
        fill: false,
      },
    ],
  };

  const [reloadChart, setReloadChart] = useState(false);

  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(initialData?.labels?.length - 1);

  const [changeInVal, setChangeInVal] = useState(0);

  const getZoomedSimpleVal = () =>
    simpleValData.slice(startPoint, endPoint + 1);

  const getZoomedData = () => {
    const dummyDatasets = [
      {
        label: "Market Value",
        data: graphDate?.pointsArray,
        borderColor: "rgba(75, 192, 192, 1)",
        fill: false,
      },
    ];

    return {
      labels: graphDate?.datesArray?.slice(startPoint, endPoint + 1),
      datasets: dummyDatasets?.map((dataset) => ({
        ...dataset,
        data: dataset?.data?.slice(startPoint, endPoint + 1),
      })),
    };
  };

  const getUpdateData = () => {
    const dummyDatasets = [
      {
        label: "Market Value",
        data: graphDate?.pointsArray,
        borderColor: "rgba(75, 192, 192, 1)",
        fill: false,
      },
    ];
    return {
      labels: graphDate?.datesArray,
      datasets: dummyDatasets?.map((dataset) => ({
        ...dataset,
        data: dataset?.data,
      })),
    };
  };

  const [data, setData] = useState(getZoomedData());

  const refreshChartData = () => {
    // this function is being used after adding a new marker in chart

    setIsGraphUpdate(!isGraphUpdate);

    const newData = getUpdateData();

    dummySimpleValRef.current = simpleValData;

    setDummySimpleVal(simpleValData);
    setData(newData);
  };

  const updateChartData = () => {
    setIsGraphUpdate(!isGraphUpdate);

    // const newData = getUpdateData();
    const newData = getZoomedData();
    const newSimpleVal = getZoomedSimpleVal();

    dummySimpleValRef.current = newSimpleVal;

    setDummySimpleVal(simpleValData);
    setData(newData);
    // setReloadChart(true);
    // setTimeout(() => setReloadChart(false), 1000);
  };

  const labelOptions = initialData?.labels?.map((label, index) => {
    if (label === "Invalid Date") return;

    return (
      <MenuItem key={index} value={index}>
        {label}
      </MenuItem>
    );
  });

  function countDigits(number) {
    var numberString = Math.abs(number).toString();

    return numberString.length;
  }

  useEffect(() => {
    handleChangeInVal(initialData, startPoint, endPoint);
  }, [startPoint, endPoint]);

  const handleChangeInVal = (initialData, startPoint, endPoint) => {
    if (initialData.datasets[0].data.length) {
      setChangeInVal(
        initialData.datasets[0].data[endPoint] -
          initialData.datasets[0].data[startPoint]
      );
    } else {
      setChangeInVal(0);
    }
  };

  const customTooltipPlugin = {
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      chart.data.datasets?.forEach((dataset, datasetIndex) => {
        const meta = chart.getDatasetMeta(datasetIndex);
        if (!meta.hidden) {
          meta.data?.forEach((element, index) => {
            if (
              dummySimpleValRef.current &&
              dummySimpleValRef.current[index]?.name
            ) {
              const x = element.x;
              const y = element.y - 23; // Adjust the y-coordinate to move the shape above the data point
              // const tooltipText = formateDate(dummySimpleVal[index].date);
              const tooltipText = dummySimpleValRef.current[index].seqnumber;

              const rectangleWidth = 30; // Adjust the rectangle width
              const rectangleHeight = 25; // Adjust the rectangle height
              const triangleHeight = 8; // Adjust the triangle height

              // Set the text color to red
              // ctx.fillStyle = "#0c0f7c";
              ctx.fillStyle = "black";

              const digits = countDigits(
                dummySimpleValRef.current[index].seqnumber
              );

              let spaceMinus;
              if (digits === 1) {
                spaceMinus = 5; //single
              } else if (digits === 2) {
                spaceMinus = 10; //double
              } else if (digits === 3) {
                spaceMinus = 15; //triple
              } else if (digits === 4) {
                spaceMinus = 17; //tetra
              }

              // Draw the rectangle with a pointy base
              ctx.beginPath();
              ctx.moveTo(x - rectangleWidth / 2, y - rectangleHeight / 2);
              ctx.lineTo(x + rectangleWidth / 2, y - rectangleHeight / 2);
              ctx.lineTo(x + rectangleWidth / 2, y + rectangleHeight / 2);
              ctx.lineTo(x - rectangleWidth / 2, y + rectangleHeight / 2);
              ctx.closePath();

              let color;

              if (dummySimpleValRef.current[index].marketVal <= 0) {
                color = "red";
              } else {
                color = "#00bdff";
              }

              ctx.fillStyle = color;
              ctx.fill(); // Fill the rectangle

              // Draw the triangle at the base
              ctx.beginPath();
              ctx.moveTo(x - triangleHeight / 2, y + rectangleHeight / 2);
              ctx.lineTo(x + triangleHeight / 2, y + rectangleHeight / 2);
              ctx.lineTo(x, y + rectangleHeight / 2 + triangleHeight);
              ctx.closePath();
              ctx.fillStyle = color; // Set the triangle color
              ctx.fill(); // Fill the triangle

              ctx.strokeStyle = color; // Set the border color to black
              ctx.lineWidth = 2; // Adjust border thickness if needed
              ctx.stroke(); // Stroke the border

              // Increase the text size
              ctx.font = "15px Arial"; // Set the desired font size and family
              ctx.fillStyle = "white";
              ctx.fillText(tooltipText, x - spaceMinus, y + 5);
              // const textY = y + 5;
              // ctx.fillText(tooltipText, x - spaceMinus, textY);
            }
          });
        }
      });

      const canvas = chart.canvas;

      canvas.addEventListener("mouseleave", (event) => {
        handleMouseLeave();
      });

      canvas.addEventListener("mousemove", (event) => {
        const rect = canvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;

        chart.data.datasets?.forEach((dataset, datasetIndex) => {
          const meta = chart.getDatasetMeta(datasetIndex);
          if (!meta.hidden) {
            meta.data?.forEach((element, index) => {
              const x = element.x;
              const y = element.y - 23;
              const rectangleWidth = 30;
              const rectangleHeight = 25;
              const digits = countDigits(
                dummySimpleValRef.current[index].seqnumber
              );
              let spaceMinus;
              if (digits === 1) {
                spaceMinus = 5; //single
              } else if (digits === 2) {
                spaceMinus = 10; //double
              } else if (digits === 3) {
                spaceMinus = 15; //triple
              } else if (digits === 4) {
                spaceMinus = 17; //tetra
              }

              if (
                mouseX >= x - rectangleWidth / 2 &&
                mouseX <= x + rectangleWidth / 2 &&
                mouseY >= y - rectangleHeight / 2 &&
                mouseY <= y + rectangleHeight / 2 &&
                dummySimpleValRef.current[index].seqnumber
              ) {
                handleMouseEnter(
                  event.x - 40,
                  event.y - 40,
                  dummySimpleValRef.current[index].name
                );
              }
            });
          }
        });
      });
    },
  };

  const handlePointClick = (event, array) => {
    try {
      const marker = dummySimpleValRef.current[array[0].index];

      handleSelectedMarkerVals(marker);
    } catch (error) {
      console.log("error", error);
    }
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          padding: 30,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        titleFont: {
          size: GRAPH_TOOLTIP_SETTINGS.titleFont,
        },
        bodyFont: {
          size: GRAPH_TOOLTIP_SETTINGS.bodyFont,
        },
        padding: GRAPH_TOOLTIP_SETTINGS.padding,
      },
    },
    onClick: handlePointClick,
  };

  const handleEndPoint = (e) => {
    var date1 = new Date(startPoint);
    var date2 = new Date(e.target.value);

    if (date1 < date2) {
      setEndPoint(e.target.value);
    } else {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "End date should be greater",
        },
      });
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid
          container
          mb={3}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid display={"flex"}>
            <Grid
              item
              style={{
                marginRight: "5px",
              }}
            >
              <Select
                className="financial_data_select"
                value={startPoint}
                label="Start Point"
                onChange={(e) => setStartPoint(e.target.value)}
              >
                {labelOptions}
              </Select>
            </Grid>
            <Grid
              item
              style={{
                marginRight: "5px",
              }}
            >
              <Select
                className="financial_data_select"
                value={endPoint}
                label="End Point"
                onChange={handleEndPoint}
              >
                {labelOptions}
              </Select>
            </Grid>

            <Button
              className="filter_button"
              variant="contained"
              onClick={updateChartData}
            >
              Filter
            </Button>
          </Grid>
          <Typography
            style={{
              fontSize: "15px",
              padding: "8px 0px",
              fontWeight: 600,
            }}
          >
            Change In Value:{" "}
            <span
              className={changeInVal < 0 ? "changeInValNeg" : "changeInValPos"}
            >
              {handleSign(changeInVal)}{" "}
              {currency_symbols[selectedCompany.currency]}
              {Math.abs(changeInVal).toLocaleString()}
            </span>
          </Typography>
        </Grid>
        {totalEquity ? (
          <Typography
            style={{
              fontSize: "15px",
              padding: "8px 0px",
              fontWeight: 600,
            }}
          >
            BOOK VALUE:
            {currency_symbols[selectedCompany.currency]}
            {parseFloat(totalEquity?.toFixed(2)).toLocaleString()}
          </Typography>
        ) : null}
        <Grid container item xs={12}>
          <Line
            ref={chartRef}
            style={{ width: "100%" }}
            options={options}
            data={data}
            plugins={[customTooltipPlugin]}
            redraw={reloadChart}
          />
          <Tooltip
            x={tooltipPosition.x}
            y={tooltipPosition.y}
            text={tooltipPosition.text}
            isVisible={tooltipVisible}
          />
        </Grid>
      </Grid>

      <SnackBar
        open={openSnackbar}
        snackType={snackType ? "success" : "error"}
        msg={message}
      />
    </>
  );
};

export default MilestonesLineGraph;
