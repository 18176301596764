const initialState = {
  periods: [],
  industryAverage: [],
  financialData: {},
  company: {},
  companyDetailsLoading: false,
};

const companyDetailReducers = (state = initialState, action) => {
  switch (action.type) {
    case "COMP_DETAILS_LOADING":
      return {
        ...state,
        companyDetailsLoading: true,
      };

    case "FETCH_COMP_DETAILS":
      return {
        ...state,
        periods: action.payload.years,
        industryAverage: action.payload.industryAverage,
        financialData: action.payload.hiddenCashes,
        company: action.payload.data[0],
        companyDetailsLoading: false,
      };

    case "ASSIGN_PERIODS":
      return {
        ...state,
        periods: action.payload.data,
      };

    case "UPDATE_FINANCIAL_DATA":
      return {
        ...state,
        financialData: action.payload.data,
      };

    case "UPDATE_SELECTED_COMP_DETAIL":
      return {
        ...state,
        company: action.payload,
      };

    case "STOP_COMP_DETAILS_LOADING":
      return {
        ...state,
        companyDetailsLoading: false,
      };
    case "INIT_STATES":
      return { ...state, ...initialState };

    default:
      return state;
  }
};
export default companyDetailReducers;
