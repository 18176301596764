const initialState = {
  isDashboard: false,
  isGoalsAndPlanning: false,
  isGuidedCoaching: false,
  isFinancialData: false,
  isInsightAndAnalysis: false,
  isReports: false,
};

const sidenavReducers = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_STATE":
      return {
        ...state,
        [action.payload.category]: action.payload.value,
      };
    case "INIT_SIDENAV":
      return {
        ...state,
        initialState,
      };
    default:
      return state;
  }
};
export default sidenavReducers;
