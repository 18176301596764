import { Typography, Box, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clientCheck } from "../../utils/utilFunctions";

const EstimateValue = ({
  selectedScenario,
  latestEquity,
  formateWithCurrency,
  user,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Grid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" fontWeight="bold" mb={4}>
            Estimate Company Value
          </Typography>
          <Button
            onClick={() => {
              if (!clientCheck(user, dispatch)) return;
              navigate("/financial-data?tab=4");
            }}
            className={user.isClient ? "disabled-simple_val" : "simple_val"}
            id="period-details-btn"
          >
            $ Simple Valuation
          </Button>
        </Grid>
        <Grid display={"flex"}>
          <Grid>
            <Typography fontWeight={"bold"} fontSize={"25px"}>
              Equity Value
            </Typography>
            <Typography>Market Value - Long Term Debt</Typography>
          </Grid>
          <Typography
            sx={{
              fontWeight: "500",
              marginLeft: "30px",
              fontSize: "50px",
              color: "#00bc8b",
            }}
          >
            {latestEquity
              ? formateWithCurrency(
                  parseInt(selectedScenario?.isIncludeSales ? latestEquity : 0)
                )
              : "0"}
          </Typography>
        </Grid>
      </Box>
    </>
  );
};
export default EstimateValue;
