import React from "react";

const CompanyLogo = ({ companyName }) => {
  // Get the first letter of the company name
  const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

  // Style for the red circle
  const circleStyle = {
    width: "40px", // Set your desired logo size
    height: "40px", // Set your desired logo size
    borderRadius: "50%",
    backgroundColor: "#b7959b",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "1.5em",
    marginRight: "10px",
  };

  return <div style={circleStyle}>{firstLetter}</div>;
};
export default CompanyLogo;
