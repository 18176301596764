import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { GRAPH_TOOLTIP_SETTINGS } from "../../utils/constants";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const PieChart = ({ priorSaving, currSavingGoal, formateWithCurrency }) => {
  const data = {
    labels: ["Saved", "Still Needs"],
    datasets: [
      {
        data: [
          parseInt(priorSaving),
          parseInt(currSavingGoal < 1 ? 0 : currSavingGoal),
        ], // Your two values here
        backgroundColor: ["#808080", "#36A2EB"],
        borderColor: ["#808080", "#36A2EB"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom", // Display legends below the chart
      },

      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw; // Get the value from the tooltip item
            return formateWithCurrency(parseInt(value)); // Format it to include the $ symbol
          },
        },
        enabled: true,
        titleFont: {
          size: GRAPH_TOOLTIP_SETTINGS.titleFont,
        },
        bodyFont: {
          size: GRAPH_TOOLTIP_SETTINGS.bodyFont,
        },
        padding: GRAPH_TOOLTIP_SETTINGS.padding,
      },
    },
  };

  return (
    <div className="pie-chart-container">
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
