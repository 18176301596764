import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import HorizontalGauge from "../guage";

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 50, // Increase the height
    background:
      "linear-gradient(to right, #FF5252 0%, #FF5252 32.88%, #00BC8B 32.88%, #00BC8B 63.50%, #00BDFF 63.66%, #00BDFF 100%)", // Set the linear gradient background with three colors
    boxShadow: "none", // Remove the default box shadow
    borderRadius: 20, // Add curved edges
    position: "relative",
  },
  text: {
    fontSize: "20px",
    position: "absolute",
    color: "#FFFFFF",
    textAlign: "center",
    width: "100%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: 0, // Make the text bold
  },
}));

function CustomAppBarNetMargin({ currentRatioScale, currentVal, industryAvg }) {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Typography
          variant="body1"
          className={classes.text}
          style={{ left: "16%" }}
        >
          Below Average
        </Typography>
        <Typography
          variant="body1"
          className={classes.text}
          style={{ left: "48.50%" }}
        >
          Average {industryAvg}
        </Typography>
        <Typography
          variant="body1"
          className={classes.text}
          style={{ left: "80%" }}
        >
          Above Average
        </Typography>
      </AppBar>
      {currentRatioScale ? (
        <HorizontalGauge
          currentRatioScale={currentRatioScale}
          value={currentVal}
          maxValue={currentRatioScale[currentRatioScale.length - 1]}
          numMarks={13}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default CustomAppBarNetMargin;
