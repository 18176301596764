import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import _ from "lodash";
import { Layout } from "../../layout";
import { CardWrapper, Accordian } from "../../components";
import CurrencyInput from "../financial-data/CurrencyInput.jsx";
import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  MenuItem,
  Slider,
  Select,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ActionLogTable from "../../components/action-log-table/index.jsx";
import { FMF_LOG } from "../../utils/constants.js";

import DotIcon from "../../assets/DotIcon.svg";
import minusIcon from "../../assets/minusIcon.svg";
import equalIcon from "../../assets/equalIcon.svg";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Analytics from "../../assets/analytics-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import ServiceComp from "./serviceComp.jsx";
import InventoryComp from "./InventoryComp.jsx";
import currency_symbols from "../../utils/currency.js";
import { fetchFMFNotes } from "../../redux/actions/notes.js";
import html2canvas from "html2canvas";
import { updatePDFData } from "../../redux/actions/pdfGraph.js";
import SnackBar from "../../components/snackBar";
import { clientCheck, handlInfo } from "../../utils/utilFunctions.js";
import IOSSwitch from "../../components/Toggle/index.js";
import { updateCompany } from "../../redux/actions/company.js";
import { IoIosWarning } from "react-icons/io";
import { clearSnack } from "../../utils/utilFunctions.js";
import { getActionLogByCompany } from "../../redux/actions/actionLog.js";

const FastMoneyFormula = () => {
  const dispatch = useDispatch();

  const { user, userEmail } = useSelector((state) => state.userReducer);

  const { selectedCompany } = useSelector((state) => state.companyReducer);

  const [reload, setReload] = useState(false);

  const { fmfActionLogs } = useSelector((state) => state.actionLogReducer);

  const { openSnackbar, snackType, message } = useSelector(
    (state) => state.snackbarReducer
  );

  const fmfInventoryGraphRef = useRef(null);
  const fmfInventoryWhatIfRef = useRef(null);

  const fmfInventoryNotesRef = useRef(null);
  const fmfPayrollNotesRef = useRef(null);

  const fmfWhatIfRef = useRef(null);

  const fmfPayrollGrapRef = useRef(null);
  const fmfPayrollWhatIfRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState("");
  const [periodData, setPeriodData] = useState("");
  // const [selectedDate, setSelectedDate] = useState("");
  const [youNeedService, setYouNeedService] = useState("");

  const [chartInventoryDays, setChartInventoryDays] = useState("");
  const [chartARDays, setChartARDays] = useState("");
  const [chartAPDays, setChartAPDays] = useState("");

  const [initChartInventoryDays, setInitChartInventoryDays] = useState("");
  const [initChartARDays, setInitChartARDays] = useState("");
  const [initChartAPDays, setInitChartAPDays] = useState("");
  const [initAvgPayrollExpense, setInitAvgPayrollExpense] = useState("");
  const [avgPayrollExpense, setAvgPayrollExpense] = useState("");
  const [daysInPayroll, setDaysInPayroll] = useState("");

  const [youHave, setYouHave] = useState("");
  const [shortage, setShortage] = useState("");
  const [isPositive, setIsPositive] = useState();
  const [youNeed, setYouNeed] = useState("");
  const [dumInvVal, setDumInvVal] = useState(chartInventoryDays);
  const [dumARVal, setDumARVal] = useState(chartARDays);
  const [dumAPVal, setDumAPVal] = useState(chartAPDays);
  const [dumAvgVal, setDumAvgVal] = useState(avgPayrollExpense);

  const [lineOfCred, setLineOfCredit] = useState({
    creditLimit: "",
    balance: "",
  });

  const [available, setAvailable] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const [isGraphUpdate, setIsGraphUpdate] = useState(false);

  const financialGapTest = chartInventoryDays + chartARDays - chartAPDays;

  const {
    periods,
    industryAverage,
    financialData,
    company,
    companyDetailsLoading,
  } = useSelector((state) => state.companyDetailReducer);

  const { inventCompFMFNotes, serviceCompFMFNotes } = useSelector(
    (state) => state.notesReducer
  );

  const checkBeforeSavePDF = () => {
    if (!isSaving) {
      setIsSaving(true);
      savePDFData();
    }
  };

  useEffect(() => {
    const payload = {
      companyId: selectedCompany._id,
      type: "FMF",
    };
    dispatch(getActionLogByCompany(payload));
  }, [dispatch, selectedCompany._id, periodData._id, reload]);

  const savePDFData = () => {
    dispatch({
      type: "GRAPH_LOADING",
    });
    if (company?.isService) {
      setTimeout(() => saveFmfPayPDF(), 1000);
    } else {
      setTimeout(() => saveFmfInvPDF(), 1000);
    }
  };

  useEffect(() => {
    savePDFData();
  }, [isGraphUpdate]);

  const saveFmfPayPDF = async () => {
    // setPDFLoading(true);

    if (!fmfPayrollGrapRef.current || !fmfWhatIfRef.current) {
      dispatch({
        type: "STOP_GRAPH_LOADING",
      });
      return;
    }

    const fmfServiceGraphCanvas = await html2canvas(fmfPayrollGrapRef.current);
    const base64ImagefmfServiceGraph = await fmfServiceGraphCanvas.toDataURL(
      "image/png"
    );

    const whatIffmfServiceCanvas = await html2canvas(fmfWhatIfRef.current);
    const base64ImagefmfServiceWhatIf = await whatIffmfServiceCanvas.toDataURL(
      "image/png"
    );

    const fmfPayrollNotesCanvas = await html2canvas(fmfPayrollNotesRef.current);
    const fmfPayrollNotesImage = await fmfPayrollNotesCanvas.toDataURL(
      "image/png"
    );

    const payload = {
      notes: {
        fmfServiceWhatIf: fmfPayrollNotesImage,
      },
      images: {
        fmfServiceGraph: base64ImagefmfServiceGraph,
        fmfServiceWhatIf: base64ImagefmfServiceWhatIf,
      },
    };

    dispatch(updatePDFData(payload));
    dispatch({
      type: "STOP_GRAPH_LOADING",
    });

    setIsSaving(false);
  };

  const saveFmfInvPDF = async () => {
    // setPDFLoading(true);

    if (!fmfInventoryGraphRef.current || !fmfWhatIfRef.current) {
      dispatch({
        type: "STOP_GRAPH_LOADING",
      });
      return;
    }

    const fmfInvGraphCanvas = await html2canvas(fmfInventoryGraphRef.current);
    const base64ImagefmfInvGraph = await fmfInvGraphCanvas.toDataURL(
      "image/png"
    );

    const fmfInvWhatIfCanvas = await html2canvas(fmfWhatIfRef.current);
    const base64ImageWhatfmfInvIf = await fmfInvWhatIfCanvas.toDataURL(
      "image/png"
    );

    const fmfInvNotesCanvas = await html2canvas(fmfInventoryNotesRef.current);
    const fmfInvNotesImage = await fmfInvNotesCanvas.toDataURL("image/png");

    const payload = {
      notes: {
        fmfInvenWhatIf: fmfInvNotesImage,
      },
      images: {
        fmfInvenGraph: base64ImagefmfInvGraph,
        fmfInvenWhatIf: base64ImageWhatfmfInvIf,
      },
    };

    dispatch(updatePDFData(payload));
    dispatch({
      type: "STOP_GRAPH_LOADING",
    });

    setIsSaving(false);
  };

  const formateWithCurrency = (amout) => {
    if (!amout) return `${currency_symbols[company.currency]}0`;
    console.log("amout", typeof amout);
    const amount = parseInt(amout)?.toFixed(0);
    return `${currency_symbols[company.currency]}${amount.toLocaleString()}`;
  };

  const convertToInt = (val) => (typeof val === "number" ? val : parseInt(val));

  const chartData = [
    { label: "Inventory Days", y: chartInventoryDays, x: chartInventoryDays },
    { label: "AP Days", y: chartAPDays, x: chartAPDays },
    { label: "AR Days", y: chartARDays, x: chartARDays },
    { label: "The Financial Gap", y: financialGapTest, x: financialGapTest },
  ];

  const chartDataService = [
    { label: "AR Days", y: chartARDays, x: chartARDays },
    {
      label: "Number of Payroll Periods",
      y: chartARDays && daysInPayroll ? chartARDays / daysInPayroll : 0,
      x: chartARDays && daysInPayroll ? chartARDays / daysInPayroll : 0,
    },
  ];

  const avgPayrollMarks = [
    {
      value: initAvgPayrollExpense,
      label: `$${parseInt(initAvgPayrollExpense)?.toLocaleString()}`,
    },
  ];

  const apDaysMarks = [{ value: initChartAPDays, label: initChartAPDays }];
  const arDaysMarks = [{ value: initChartARDays, label: initChartARDays }];
  const inventoryDaysMarks = [
    { value: initChartInventoryDays, label: initChartInventoryDays },
  ];

  const [slider, setSlider] = useState({
    slider_1: 91,
    slider_2: 34,
    slider_3: 56,
  });

  const calculation = (
    currenAssets,
    currenLiabilities,
    costOfGoodsSoldinUSD,
    financialGap
  ) => {
    const currYouHave = currenAssets - currenLiabilities;

    const currFinancialGap = financialGap;

    const currYouNeed =
      (costOfGoodsSoldinUSD / 365).toFixed(2) * currFinancialGap;

    const currShortage = currYouHave - currYouNeed;

    setYouHave(currYouHave);
    setYouNeed(currYouNeed);
    setShortage(currShortage);
    setIsPositive(currShortage > 0 ? true : false);
  };

  const calculationService = (
    currenAssets,
    currenLiabilities,
    daysInPayroll,
    avgPayrollExpense,
    ARD
  ) => {
    const currYouHave = currenAssets - currenLiabilities;

    let currYouNeed;
    if (ARD) {
      currYouNeed = (ARD / daysInPayroll) * avgPayrollExpense;
    } else {
      currYouNeed = (chartARDays / daysInPayroll) * avgPayrollExpense;
    }

    // const currYouNeed = (chartARDays / daysInPayroll) * avgPayrollExpense
    const currShortage = currYouHave - currYouNeed;

    setYouHave(currYouHave);
    setYouNeedService(currYouNeed);
    setShortage(currShortage);
    setIsPositive(currShortage > 0 ? true : false);
  };

  const handleChange = async (event) => {
    setSelectedDate(event.target.value);

    let periodData = financialData.find((x) => x.year === event.target.value);
    setPeriodData(periodData);
    // setSelectedDate(periodData.year);

    const invTrnRt = parseInt(periodData.result.inventoryTurnoverRate);
    const accRecTrnRt = parseInt(
      periodData.result.accountsReceivableTurnoverRate
    );
    const accPayTrnRt = parseInt(periodData.result.accountsPayableTurnoverRate);

    let ID = invTrnRt ? Math.ceil(365 / invTrnRt) : 0;
    let ARD = accRecTrnRt ? Math.ceil(365 / accRecTrnRt) : 0;
    let APD = accPayTrnRt ? Math.ceil(365 / accPayTrnRt) : 0;

    const FG = ID + ARD - APD;

    setYouNeedService(
      (periodData?.incomeStatement?.avgPayrollExpense * ARD) /
        periodData.incomeStatement.daysInPayroll
    );

    calculation(
      //setting up all Industry values in case user switch from service to industry
      parseInt(periodData.balanceSheet?.currenAssets),
      parseInt(periodData.balanceSheet?.currenLiabilities),
      parseInt(periodData.incomeStatement.costOfGoodsSoldinUSD),
      FG
    );

    setChartInventoryDays(ID);
    setDumInvVal(ID); /// to handle sliders in What If
    setInitChartInventoryDays(ID);
    setChartARDays(ARD);
    setDumARVal(ARD); /// to handle sliders in What If
    setInitChartARDays(ARD);
    setChartAPDays(APD);
    setDumAPVal(APD); /// to handle sliders in What If
    setInitChartAPDays(APD);

    if (company.isService) {
      setInitAvgPayrollExpense(periodData.incomeStatement?.avgPayrollExpense);
      setAvgPayrollExpense(
        parseInt(periodData.incomeStatement?.avgPayrollExpense)
      );
      setDumAvgVal(periodData.incomeStatement?.avgPayrollExpense); // to handle sliders in What If

      setDaysInPayroll(periodData.incomeStatement?.daysInPayroll);

      calculationService(
        parseInt(periodData.balanceSheet?.currenAssets),
        parseInt(periodData.balanceSheet?.currenLiabilities),
        parseInt(periodData.incomeStatement?.daysInPayroll),
        parseInt(periodData.incomeStatement?.avgPayrollExpense),
        ARD
      );
    } else {
      calculation(
        parseInt(periodData.balanceSheet?.currenAssets),
        parseInt(periodData.balanceSheet?.currenLiabilities),
        parseInt(periodData.incomeStatement.costOfGoodsSoldinUSD),
        FG
      );
    }

    const payload = {
      date: periodData.year,
      page: "FastMoneyFormula",
      companyId: company._id,
    };

    dispatch(fetchFMFNotes(payload));
    checkBeforeSavePDF();
    // setIsGraphUpdate(!isGraphUpdate);
  };

  const handleInventorySlider = (e) => {
    // const IDval = newVal;
    const IDval = convertToInt(e.target.value);
    setChartInventoryDays(IDval);

    const FG = IDval + chartARDays - chartAPDays;
    calculation(
      parseInt(periodData?.balanceSheet?.currenAssets),
      parseInt(periodData.balanceSheet?.currenLiabilities),
      parseInt(periodData.incomeStatement.costOfGoodsSoldinUSD),
      FG
    );
    checkBeforeSavePDF();
  };

  const handleAvgPayrollSlider = (e) => {
    const APRE = e.target.value;
    setAvgPayrollExpense(APRE);

    calculationService(
      parseInt(periodData?.balanceSheet?.currenAssets),
      parseInt(periodData?.balanceSheet?.currenLiabilities),
      parseInt(periodData?.incomeStatement?.daysInPayroll),
      parseInt(APRE)
    );
    checkBeforeSavePDF();
  };

  const handleARSlider = (e) => {
    const ARVal = convertToInt(e.target.value);
    setChartARDays(ARVal);

    if (company.isService) {
      calculationService(
        parseInt(periodData?.balanceSheet?.currenAssets),
        parseInt(periodData?.balanceSheet?.currenLiabilities),
        parseInt(periodData?.incomeStatement?.daysInPayroll),
        parseInt(periodData.incomeStatement?.avgPayrollExpense),
        ARVal
      );
    } else {
      const FG = chartInventoryDays + ARVal - chartAPDays;
      calculation(
        parseInt(periodData?.balanceSheet?.currenAssets),
        parseInt(periodData?.balanceSheet?.currenLiabilities),
        parseInt(periodData?.incomeStatement.costOfGoodsSoldinUSD),
        FG
      );
    }
    checkBeforeSavePDF();
  };

  const handleAPSlider = (e) => {
    const APVal = e.target.value;
    setChartAPDays(APVal);

    const FG = chartInventoryDays + chartARDays - APVal;
    calculation(
      parseInt(periodData?.balanceSheet?.currenAssets),
      parseInt(periodData?.balanceSheet?.currenLiabilities),
      parseInt(periodData?.incomeStatement.costOfGoodsSoldinUSD),
      FG
    );
    checkBeforeSavePDF();
  };

  const handleUpdateInvSlider = (e) => {
    if (e.target.value < initChartInventoryDays / 8) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: `Value is too small! smallest value can be ${
            initChartInventoryDays / 8
          }`,
        },
      });
      clearSnack(dispatch);

      return;
    }
    if (e.target.value > initChartInventoryDays * 2 + 90) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: `Value is too big! biggest value can be ${
            initChartInventoryDays * 2 + 90
          }`,
        },
      });
      clearSnack(dispatch);

      return;
    }
    handleInventorySlider(e);
  };

  const handleUpdateAvgSlider = (e) => {
    if (e.target.value < initAvgPayrollExpense / 8) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: `Value is too small! smallest value can be ${
            initAvgPayrollExpense / 8
          }`,
        },
      });
      clearSnack(dispatch);
      return;
    }
    if (e.target.value > initAvgPayrollExpense * 2) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: `Value is too big! biggest value can be ${
            initAvgPayrollExpense * 2 + 90
          }`,
        },
      });
      clearSnack(dispatch);
      return;
    }
    handleAvgPayrollSlider(e);
  };

  const handleUpdateDateAccRevSlicer = (e) => {
    if (e.target.value < initChartARDays / 8) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: `Value is too small! samllest value can be ${
            initChartARDays / 8
          }`,
        },
      });
      clearSnack(dispatch);
      return;
    }
    if (e.target.value > initChartARDays * 2 + 90) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: `Value is too big! biggest value can be ${
            initChartARDays * 2 + 90
          }`,
        },
      });
      clearSnack(dispatch);
      return;
    }
    handleARSlider(e);
  };

  const handleUpdateAccPaySlider = (e) => {
    if (e.target.value < chartAPDays / 8) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: `Value is too small! smallest value can be ${
            chartAPDays / 8
          }`,
        },
      });
      clearSnack(dispatch);

      return;
    }
    if (e.target.value > chartAPDays * 2) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: `Value is too big! biggest value can be ${chartAPDays * 2}`,
        },
      });
      clearSnack(dispatch);
      return;
    }
    handleAPSlider(e);
  };

  const resetValues = () => {
    const FG = initChartInventoryDays + initChartARDays - initChartAPDays;

    setYouNeedService(
      (periodData?.incomeStatement?.avgPayrollExpense * initChartARDays) /
        periodData?.incomeStatement?.daysInPayroll
    );

    setChartInventoryDays(initChartInventoryDays);
    setChartARDays(initChartARDays);
    setChartAPDays(initChartAPDays);
    setAvgPayrollExpense(initAvgPayrollExpense);

    setDumInvVal(initChartInventoryDays);
    setDumARVal(initChartARDays);
    setDumAPVal(initChartAPDays);
    setDumAvgVal(initAvgPayrollExpense);

    if (company.isService) {
      const accRecTrnRt = parseInt(
        periodData.result.accountsReceivableTurnoverRate
      );
      let ARD = accRecTrnRt ? Math.ceil(365 / accRecTrnRt) : 0;

      calculationService(
        parseInt(periodData?.balanceSheet?.currenAssets),
        parseInt(periodData?.balanceSheet?.currenLiabilities),
        parseInt(periodData?.incomeStatement?.daysInPayroll),
        parseInt(periodData?.incomeStatement?.avgPayrollExpense),
        ARD
      );
    } else {
      calculation(
        parseInt(periodData?.balanceSheet?.currenAssets),
        parseInt(periodData?.balanceSheet?.currenLiabilities),
        parseInt(periodData?.incomeStatement.costOfGoodsSoldinUSD),
        FG
      );
    }
  };

  const handleUpdateServiceCheck = (company, e) => {
    const payload = {
      companyName: company.companyName,
      NAICScode: company.NAICScode,
      isService: e.target.checked,
      loggedInUserEmail: userEmail,
    };

    if (e.target.checked) {
      setInitAvgPayrollExpense(periodData.incomeStatement?.avgPayrollExpense);
      setAvgPayrollExpense(periodData.incomeStatement?.avgPayrollExpense);
      setDumAvgVal(periodData.incomeStatement?.avgPayrollExpense); // to handle sliders in What If

      setDaysInPayroll(periodData.incomeStatement?.daysInPayroll);

      calculationService(
        parseInt(periodData.balanceSheet?.currenAssets),
        parseInt(periodData.balanceSheet?.currenLiabilities),
        parseInt(periodData.incomeStatement?.daysInPayroll),
        parseInt(periodData.incomeStatement?.avgPayrollExpense),
        chartARDays
      );
    }

    dispatch(updateCompany(payload, company._id));
    if (company?._id === company._id) {
      const payload = {
        ...company,
        isService: e.target.checked,
      };

      dispatch({
        type: "UPDATE_SELECTED_COMP_DETAIL",
        payload: payload,
      });

      dispatch({
        type: "SET_SELECTED_COMP",
        payload: payload,
      });
    }
  };

  const getStyles = (shortage) => {
    if (shortage >= 0) {
      return {
        color: "#00bdff",
        fontWeight: "650",
        fontSize: "25px",
        marginLeft: "10px",
      };
    } else {
      return {
        color: "#FF5252",
        fontWeight: "650",
        fontSize: "25px",
        marginLeft: "10px",
      };
    }
  };

  const getAvailCreditColor = (LOC) => {
    if (LOC > 0)
      return {
        color: "#00bdff",
        fontWeight: "650",
        fontSize: "25px",
        marginLeft: "10px",
      };
    else if (LOC < 0)
      return {
        color: "#FF5252",
        fontWeight: "650",
        fontSize: "25px",
        marginLeft: "10px",
      };
    else
      return {
        color: "black",
        fontWeight: "650",
        fontSize: "18px",
        marginLeft: "10px",
      };
  };

  const handleChangeLOC = (e) => {
    setLineOfCredit({ ...lineOfCred, [e.target.name]: e.target.value });

    if (lineOfCred.balance && lineOfCred.creditLimit) {
      setAvailable(
        parseInt(lineOfCred.creditLimit.replaceAll(",", "")) -
          parseInt(lineOfCred.balance.replaceAll(",", ""))
      );
    } else {
    }
  };

  const handleAvailableVal = (creditLimit, balance) => {
    let availableLoc = "";
    if (creditLimit && balance) {
      availableLoc =
        parseInt(lineOfCred.creditLimit.replaceAll(",", "")) -
        parseInt(lineOfCred.balance.replaceAll(",", ""));
    }

    return availableLoc;
  };

  const handleDifference = (cashFlow, creditLimit, balance) => {
    if (cashFlow && creditLimit && balance) {
      return (
        cashFlow +
        parseInt(
          creditLimit.replaceAll(",", "") -
            parseInt(balance.replaceAll(",", ""))
        )
      );
    }
    return 0;
  };

  const generateRandomLightColor = () => {
    return `#b7959b`;
  };

  const CompanyLogo = ({ companyName }) => {
    // Get the first letter of the company name
    const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

    // Style for the red circle
    const circleStyle = {
      width: "50px", // Set your desired logo size
      height: "50px", // Set your desired logo size
      borderRadius: "50%",
      backgroundColor: generateRandomLightColor(),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "1.5em",
      marginRight: "10px",
    };

    return <div style={circleStyle}>{firstLetter}</div>;
  };

  return (
    <React.Fragment>
      <Layout title="Fast Money Formula">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CompanyLogo companyName={company.companyName} />
            <Typography className="financial_data_heading">
              {company.companyName}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#F8F8FF",
                      color: "gray",
                      paddingY: "10px",
                      paddingX: "20px",
                    },
                  },
                }}
                title={
                  <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                    {company.NAICStitle}
                  </pre>
                }
                placement="bottom"
                // arrow
              >
                <Typography
                  className="financial_data_right_heading"
                  sx={{ cursor: "pointer" }}
                >
                  NAICS Code: {company.NAICScode}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </CardWrapper>

        <Box style={{ height: 10, width: "100%" }} />

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Typography className="hidden_cash_subTitle">
            The Fast Money Formula{" "}
            <InfoOutlinedIcon
              onClick={() => handlInfo("fmf")}
              className="infor_icon"
            />
          </Typography>
          <Grid
            container
            xs={4}
            md={4}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    onChange={(e) => {
                      if (!clientCheck(user, dispatch)) return;
                      handleUpdateServiceCheck(company, e);
                    }}
                    defaultChecked={company.isService}
                    disabled={selectedDate ? user.isClient : true}
                    style={{ color: user.isClient ? "grey" : "" }}
                  />
                }
                label="Service"
              />
            </>
            <Select
              onChange={handleChange}
              value={selectedDate || "Period"}
              label="years"
              className="financial_data_select"
            >
              <MenuItem disabled value={"Period"}>
                Period
              </MenuItem>
              {periods?.map((period, index) => (
                <MenuItem
                  className="adjust_period_menu_icons"
                  key={index}
                  value={period.date}
                >
                  {period.periodName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Box style={{ height: 10, width: "100%" }} />
        <CardWrapper>
          <CardWrapper border="1px solid #E7E7E7">
            <div style={{ width: "100%" }}>
              {/* <Typography variant="h5">Graph Goes Here</Typography> */}
              {
                // selectedDate ? (
                company.isService && selectedDate ? (
                  !avgPayrollExpense || !daysInPayroll ? (
                    <Typography style={{ color: "#5f5a5a" }}>
                      <div style={{ display: "flex" }}>
                        <IoIosWarning
                          style={{ fontSize: "20px", color: "red" }}
                        />
                        <p style={{ paddingLeft: "10px" }}>
                          Please fill out all the details in the selected period
                        </p>
                      </div>
                    </Typography>
                  ) : (
                    <React.Fragment>
                      <Box
                        ref={fmfPayrollGrapRef}
                        sx={{
                          padding: "0 10px",
                          width: "100%",
                        }}
                      >
                        <ServiceComp
                          // handleARSlider={handleARSliderService}
                          initChartARDays={initChartARDays}
                          chartARDays={chartARDays}
                          chartData={chartDataService}
                          // handleClick={handleClick}
                          currency={company.currency}
                          youHave={youHave}
                          youNeed={youNeedService}
                          shortage={shortage}
                          isPositive={isPositive}
                          avgPayrollExpense={avgPayrollExpense}
                          initAvgPayrollExpense={initAvgPayrollExpense}
                          // handleAvgPayrollSlider={handleAvgPayrollSlider}
                          // resetServiceCompValues={resetServiceCompValues}
                          daysInPayroll={daysInPayroll}
                          selectData={periodData}
                          // pdfGraph={pdfGraph}
                          // setPdfGraph={setPdfGraph}
                          // fmfPayrollGrapRef={fmfPayrollGrapRef}
                          // fmfPayrollWhatIfRef={fmfPayrollWhatIfRef}
                        />
                      </Box>
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <Box ref={fmfInventoryGraphRef} sx={{ padding: "0 10px" }}>
                      <InventoryComp
                        // handleAPSlider={handleAPder}
                        selectData={periodData}
                        chartAPDays={chartAPDays}
                        initChartAPDays={initChartAPDays}
                        // handleARSlider={handleARSlider}
                        initChartARDays={initChartARDays}
                        chartARDays={chartARDays}
                        chartData={chartData}
                        // handleClick={handleClick}
                        currency={financialData?.companyId?.currency}
                        youHave={youHave}
                        youNeed={youNeed}
                        shortage={shortage}
                        isPositive={isPositive}
                        chartInventoryDays={chartInventoryDays}
                        initChartInventoryDays={initChartInventoryDays}
                        // handleInventorySlider={handleInventorySlider}
                        // resetInvenCompValues={resetInvenCompValues}
                        // pdfGraph={pdfGraph}
                        // setPdfGraph={setPdfGraph}
                        // fmfInventoryGraphRef={fmfInventoryGraphRef}
                        // fmfInventoryWhatIfRef={fmfInventoryWhatIfRef}

                        dumInvVal={dumInvVal}
                        setDumInvVal={setDumInvVal}
                        dumARVal={dumARVal}
                        setDumARVal={setDumARVal}
                        dumAPVal={dumAPVal}
                        setDumAPVal={setDumAPVal}
                      />
                    </Box>
                  </React.Fragment>
                )
                // ) : (
                //   <Typography style={{ color: "#bfbfbf" }}>
                //     Select Year to Start
                //   </Typography>
                // )
              }
            </div>
          </CardWrapper>
          {
            // selectedDate ? (
            selectedDate &&
            company.isService &&
            (!avgPayrollExpense || !daysInPayroll) ? (
              <></>
            ) : (
              <>
                <Box style={{ height: 30, width: "100%" }} />
                <Box ref={fmfWhatIfRef}>
                  <CardWrapper border="1px solid #E7E7E7">
                    <Typography className="Simulation_heading">
                      What If Simulation
                    </Typography>
                    {/* <Typography
                      style={{
                        textAlign: "right",
                        height: 30,
                        width: "100%",
                        marginRight: "190px",
                      }}
                    >
                      Line of Credit Details{" "}
                    </Typography> */}
                    <Box style={{ height: 10, width: "100%" }} />
                    <Grid
                      container
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          // gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="Simulation_record">
                          You Have:{" "}
                          <span style={{ color: "#47CEB2", fontWeight: "600" }}>
                            {youHave
                              ? `${
                                  currency_symbols[company.currency]
                                } ${parseFloat(
                                  youHave?.toFixed(2)
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : 0}
                          </span>
                        </Typography>
                        <img src={DotIcon} className="DotIcon" />
                        <Typography className="Simulation_record2">
                          You Need:{" "}
                          <Typography className="Simulation_record3">
                            {company.isService
                              ? youNeedService
                                ? `${
                                    currency_symbols[company.currency]
                                  } ${parseFloat(
                                    youNeedService?.toFixed(2)
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "0"
                              : youNeed
                              ? `${
                                  currency_symbols[company.currency]
                                } ${parseFloat(
                                  youNeed?.toFixed(2)
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : "0"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} style={{}}>
                      <Grid>
                        <Grid xs={12} display="flex" gap={2}>
                          <Grid
                            xs={4}
                            // display="flex"
                            justifyContent={"space-between"}
                            alignItems="center"
                            gap={1}
                          >
                            <Typography className="Headings_fastmoney">
                              Line of Credit Limit
                            </Typography>

                            <Box className="tabs_content_input_box_loc">
                              <Box className="dollarSign">$</Box>

                              <CurrencyInput
                                className="line_of_credit_inputs2"
                                onChange={handleChangeLOC}
                                name="creditLimit"
                                value={lineOfCred.creditLimit}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            xs={1}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center", // Center vertically
                              alignItems: "center", // Center horizontally
                              textAlign: "center", // Ensure text alignment is centered
                            }}
                          >
                            <img
                              className="icons_financial_data"
                              src={minusIcon}
                              alt="minus"
                            />
                          </Grid>
                          <Grid
                            xs={4}
                            // display="flex"
                            justifyContent={"space-between"}
                            alignItems="center"
                            gap={1}
                          >
                            <Typography className="Headings_fastmoney">
                              Line of Credit Balance
                            </Typography>
                            <Box className="tabs_content_input_box_loc">
                              <Box className="dollarSign">$</Box>

                              <CurrencyInput
                                className="line_of_credit_inputs2"
                                onChange={handleChangeLOC}
                                name="balance"
                                value={lineOfCred.balance}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            xs={1}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center", // Center vertically
                              alignItems: "center", // Center horizontally
                              textAlign: "center", // Ensure text alignment is centered
                            }}
                          >
                            <img
                              className="icons_financial_data"
                              src={equalIcon}
                              alt="equal"
                            />
                          </Grid>
                          <Grid
                            xs={3}
                            style={{
                              // display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className="Headings_fastmoney">
                              Available
                            </Typography>
                            <Grid>
                              <Typography>
                                <Box className="tabs_content_input_box_loc">
                                  <Box className="dollarSign2">$</Box>

                                  {/* {currency_symbols[company.currency]}{" "} */}
                                  <Typography
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      // float: "left",
                                    }}
                                    className="line_of_credit_inputs2"
                                  >
                                    {handleAvailableVal(
                                      lineOfCred.creditLimit,
                                      lineOfCred.balance
                                    )
                                      ? parseFloat(
                                          handleAvailableVal(
                                            lineOfCred.creditLimit,
                                            lineOfCred.balance
                                          )?.toFixed(2)
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </Typography>
                                </Box>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <hr style={{ margin: "10px 0" }} /> */}
                        {/* <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Available</Typography>
                          <Typography>
                            {currency_symbols[company.currency]}{" "}
                            {handleAvailableVal(
                              lineOfCred.creditLimit,
                              lineOfCred.balance
                            )
                              ? parseFloat(
                                  handleAvailableVal(
                                    lineOfCred.creditLimit,
                                    lineOfCred.balance
                                  )?.toFixed(2)
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "0.00"}
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </Grid>

                    {/* <Box style={{ height: 25, width: "100%" }} /> */}
                    <Grid
                      container
                      xs={12}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      {/* <Typography className="Simulation_record_balance">
                        Balance Sheet Goal
                      </Typography> */}
                    </Grid>
                    <Box
                      style={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
                        margin: "20px 0px",
                      }}
                    />
                    {company?.isService ? (
                      ""
                    ) : (
                      <Grid
                        container
                        xs={7}
                        style={{
                          paddingBottom: "30px",
                        }}
                      >
                        <Grid
                          item
                          xs={7}
                          style={{
                            fontWeight: "600",
                            fontSize: 22,
                            paddingBottom: "20px",
                          }}
                        >
                          Inventory
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Slider
                            style={{ width: "100%" }}
                            value={chartInventoryDays}
                            min={initChartInventoryDays / 8}
                            max={initChartInventoryDays * 2 + 90}
                            step={1}
                            onChange={(e) => {
                              setDumInvVal(e.target.value);
                              handleInventorySlider(e);
                            }}
                            marks={inventoryDaysMarks}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            height: "max",
                            display: "Flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <input
                            onChange={(e) => setDumInvVal(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                handleUpdateInvSlider(e);
                              }
                            }}
                            onBlur={(e) => {
                              handleUpdateInvSlider(e);
                            }}
                            value={dumInvVal}
                            style={{
                              width: 60,
                              height: 31,
                              borderRadius: 5,
                              border: "1px solid rgba(0, 0, 0, 0.10)",
                              textAlign: "center",
                              marginLeft: "10px",
                              padding: "5px 10px",
                            }}
                          />

                          {/* </input> */}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            fontSize: 18,
                            textAlign: "left",
                            paddingTop: "10px",
                          }}
                        >
                          <Grid
                            style={{
                              display: "flex",
                              gap: " 10px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                display: "flex",
                                alignItems: "center",
                                // justifyItems: "left",
                              }}
                            >
                              <img src={DotIcon} className="DotIcon" />
                              Balance Sheet Goal{" "}
                            </Typography>
                            <Typography
                              style={{
                                color: "gray",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              {currency_symbols[company.currency]}
                              {chartInventoryDays &&
                              Object.keys(periodData).length
                                ? `${Math.abs(
                                    (
                                      (chartInventoryDays *
                                        periodData?.incomeStatement
                                          ?.costOfGoodsSoldinUSD) /
                                      365
                                    )?.toFixed(2)
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "0"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {/* <Box
                      style={{
                        width: "100%",
                        // borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                        margin: "20px 0px",
                      }}
                    /> */}
                    <Grid
                      xs={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid
                        container
                        xs={7}
                        style={{
                          paddingBottom: "30px",
                        }}
                      >
                        <Grid
                          item
                          xs={7}
                          style={{
                            fontWeight: "600",
                            fontSize: 22,
                            paddingBottom: "20px",
                          }}
                        >
                          Accounts Receivable
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Slider
                            style={{ width: "100%" }}
                            value={chartARDays}
                            min={initChartARDays / 8}
                            max={initChartARDays * 2 + 90}
                            step={1}
                            onChange={(e) => {
                              setDumARVal(e.target.value);
                              handleARSlider(e);
                            }}
                            marks={arDaysMarks}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            height: "max",
                            display: "Flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <input
                            onChange={(e) => setDumARVal(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                handleUpdateDateAccRevSlicer(e);
                              }
                            }}
                            onBlur={(e) => {
                              handleUpdateDateAccRevSlicer(e);
                            }}
                            value={dumARVal}
                            style={{
                              width: 60,
                              height: 31,
                              borderRadius: 5,
                              border: "1px solid rgba(0, 0, 0, 0.10)",
                              textAlign: "center",
                              marginLeft: "10px",
                              padding: "5px 10px",
                            }}
                          />
                          {/* <Box
                            style={{
                              width: 60,
                              height: 31,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                              border: "1px solid rgba(0, 0, 0, 0.10)",
                            }}
                          >
                            {dumARVal}
                          </Box> */}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ fontSize: 18, textAlign: "right" }}
                        >
                          <Grid
                            style={{
                              display: "flex",
                              gap: " 10px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                display: "flex",
                                alignItems: "center",
                                // justifyItems: "left",
                              }}
                            >
                              <img src={DotIcon} className="DotIcon" />
                              Balance Sheet Goal{" "}
                            </Typography>
                            <Typography
                              style={{
                                color: "gray",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                            >
                              {currency_symbols[company.currency]}
                              {chartARDays && Object.keys(periodData).length
                                ? `${Math.abs(
                                    (
                                      (chartARDays *
                                        periodData?.incomeStatement
                                          ?.salesinUSD) /
                                      365
                                    )?.toFixed(2)
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "0"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={5}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center", // Center vertically
                          alignItems: "center", // Center horizontally
                          textAlign: "center", // Ensure text alignment is centered
                        }}
                      >
                        <Typography className="cashFlowStatus">
                          Cash Flow Status
                          <span style={getStyles(shortage)}>
                            {currency_symbols[company.currency]}
                            {shortage
                              ? parseFloat(shortage?.toFixed(2)).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : "0"}
                          </span>
                        </Typography>
                        <Box style={{ height: 10, width: "100%" }} />
                        <Typography className="difference">
                          With Available Credit
                          <Typography
                            style={getAvailCreditColor(
                              handleDifference(
                                shortage,
                                lineOfCred.creditLimit,
                                lineOfCred.balance
                              )
                            )}
                          >
                            {" "}
                            {currency_symbols[company.currency]}{" "}
                            {handleDifference(
                              shortage,
                              lineOfCred.creditLimit,
                              lineOfCred.balance
                            )
                              ? parseFloat(
                                  handleDifference(
                                    shortage,
                                    lineOfCred.creditLimit,
                                    lineOfCred.balance
                                  )?.toFixed(2)
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "0.00"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Box
                      style={{
                        width: "100%",
                        // borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                        margin: "20px 0px",
                      }}
                    /> */}

                    {company.isService ? (
                      <Grid
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Grid container xs={7}>
                          <Grid
                            item
                            xs={7}
                            style={{
                              fontWeight: "600",
                              fontSize: 22,
                              paddingBottom: "20px",
                            }}
                          >
                            Average Payroll Expense
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Slider
                              style={{ width: "100%" }}
                              value={avgPayrollExpense}
                              min={initAvgPayrollExpense / 8}
                              max={initAvgPayrollExpense * 2}
                              step={1}
                              onChange={(e) => {
                                setDumAvgVal(e.target.value);
                                handleAvgPayrollSlider(e);
                              }}
                              marks={avgPayrollMarks}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              height: "max",
                              display: "Flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            <CurrencyInput
                              value={formateWithCurrency(dumAvgVal)}
                              onChange={(e) => setDumAvgVal(e.target.value)}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  // Call your function here
                                  handleUpdateAvgSlider(e);
                                }
                              }}
                              onBlur={(e) => {
                                handleUpdateAvgSlider(e);
                              }}
                              className="avg_payroll_input_fmf"
                            />
                            {/* <Box
                            style={{
                              width: 60,
                              height: 31,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                              border: "1px solid rgba(0, 0, 0, 0.10)",
                            }}
                          >
                            {dumAPVal}
                          </Box> */}
                          </Grid>
                          {/* <Grid
                          item
                          xs={1.5}
                          style={{ fontSize: 18, textAlign: "right" }}
                        >
                          {currency_symbols[company.currency]}

                          {chartAPDays && Object.keys(periodData).length
                            ? `${Math.abs(
                                (
                                  (chartAPDays *
                                    periodData?.incomeStatement
                                      ?.costOfGoodsSoldinUSD) /
                                  365
                                )?.toFixed(2)
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : "0"}
                        </Grid> */}
                        </Grid>
                        <Grid
                          xs={5}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center", // Center vertically
                            alignItems: "center", // Center horizontally
                            textAlign: "center", // Ensure text alignment is centered
                            float: "right",
                          }}
                        >
                          <Button
                            className="home_add_company"
                            style={{ marginLeft: "auto", marginRight: "auto" }}
                            onClick={() => {
                              setDumAPVal(chartAPDays);
                              setDumARVal(chartARDays);
                              setDumInvVal(chartInventoryDays);
                              resetValues();
                            }}
                          >
                            Reset Values
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {company.isService ? (
                      ""
                    ) : (
                      <Grid
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          container
                          xs={7}
                          style={{
                            display: "flex",
                            alignContent: "center",
                          }}
                        >
                          <Grid
                            item
                            xs={7}
                            style={{
                              fontWeight: "600",
                              fontSize: 22,
                              paddingBottom: "20px",
                            }}
                          >
                            Account Payable
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Slider
                              style={{ width: "100%" }}
                              min={initChartAPDays / 8}
                              max={initChartAPDays * 2 + 90}
                              step={1}
                              value={chartAPDays}
                              onChange={(e) => {
                                setDumAPVal(e.target.value);
                                handleAPSlider(e);
                              }}
                              marks={apDaysMarks}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              height: "max",
                              display: "Flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            <input
                              onChange={(e) => setDumAPVal(e.target.value)}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  // Call your function here
                                  handleUpdateAccPaySlider(e);
                                }
                              }}
                              onBlur={(e) => {
                                handleUpdateAccPaySlider(e);
                              }}
                              value={dumAPVal}
                              style={{
                                width: 60,
                                height: 31,
                                borderRadius: 5,
                                border: "1px solid rgba(0, 0, 0, 0.10)",
                                textAlign: "center",
                                marginLeft: "10px",
                                padding: "5px 10px",
                              }}
                            />
                            {/* <Box
                              style={{
                                width: 60,
                                height: 31,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 5,
                                border: "1px solid rgba(0, 0, 0, 0.10)",
                              }}
                            >
                              {dumAPVal}
                            </Box> */}
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{ fontSize: 18, textAlign: "right" }}
                          >
                            <Grid
                              style={{
                                display: "flex",
                                gap: " 10px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  display: "flex",
                                  alignItems: "center",
                                  // justifyItems: "left",
                                }}
                              >
                                <img src={DotIcon} className="DotIcon" />
                                Balance Sheet Goal{" "}
                              </Typography>
                              <Typography
                                style={{
                                  color: "gray",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                }}
                              >
                                {currency_symbols[company.currency]}

                                {chartAPDays && Object.keys(periodData).length
                                  ? `${Math.abs(
                                      (
                                        (chartAPDays *
                                          periodData?.incomeStatement
                                            ?.costOfGoodsSoldinUSD) /
                                        365
                                      )?.toFixed(2)
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}`
                                  : "0"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={5}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center", // Center vertically
                            alignItems: "center", // Center horizontally
                            textAlign: "center", // Ensure text alignment is centered
                            float: "right",
                          }}
                        >
                          <Button
                            className="home_add_company"
                            style={{ marginLeft: "auto", marginRight: "auto" }}
                            onClick={() => {
                              setDumAPVal(chartAPDays);
                              setDumARVal(chartARDays);
                              setDumInvVal(chartInventoryDays);
                              resetValues();
                            }}
                          >
                            Reset Values
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {/* <Box
                      style={{
                        width: "100%",
                        // borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                        margin: "20px 0px",
                      }}
                    /> */}

                    {/* <Button
                      className="home_add_company"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                      onClick={() => {
                        setDumAPVal(chartAPDays);
                        setDumARVal(chartARDays);
                        setDumInvVal(chartInventoryDays);
                        resetValues();
                      }}
                    >
                      Reset Values
                    </Button> */}

                    {/* <Box style={{ height: 25, width: "100%" }} /> */}
                  </CardWrapper>
                </Box>
                <CardWrapper>
                  <Grid item xs={12}>
                    <Box style={{ height: 20, width: "100%" }} />
                    <Divider />
                    <Box style={{ height: 20, width: "100%" }} />
                    <Grid container xs={12}>
                      {company.isService ? (
                        <Accordian
                          noteData={serviceCompFMFNotes}
                          key1="serviceCompFMFNotes"
                          selectedDate={selectedDate}
                          notesRef={fmfPayrollNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      ) : (
                        <Accordian
                          noteData={inventCompFMFNotes}
                          key1="inventCompFMFNotes"
                          selectedDate={selectedDate}
                          notesRef={fmfInventoryNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      )}

                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <ActionLogTable
                        setReload={setReload}
                        actionLogs={fmfActionLogs}
                        periodData={periodData}
                        selectedCompany={selectedCompany}
                        user={user}
                        type={FMF_LOG}
                      />
                    </Grid>
                  </Grid>
                </CardWrapper>
              </>
            )
            // ) : (
            //   ""
            // )
          }
        </CardWrapper>
      </Layout>
      <SnackBar
        // setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType}
        msg={message}
      />
    </React.Fragment>
  );
};
export default FastMoneyFormula;
